import React from "react";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../ModalSkeleton";
import styles from "./ViewLatestNewsModal.module.css";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import UploadImageBox from "../../Component/UploadImageBox";
import moment from "moment";
import Parser from 'html-react-parser'

const ViewLatestNewsModal = ({ show, setShow, data }) => {
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"View Latest News"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={12}>
          <UploadImageBox state={data?.photo} edit={false}/>
        </Col>
        <Col md={12}>
          <label>Title:</label>
          <p>{Parser(data?.newsTopic)}</p>
        </Col>
        <Col md={12}>
          <label>Date:</label>
          <p>{moment(data?.date).format('DD MMM, YYYY')}</p>
        </Col>
        <Col md={12}>
          <label>Description:</label>
          <ShowMoreShowLessText text={data?.description} visibility={200} />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default ViewLatestNewsModal;
