import "./assets/Styles/style.css";
import "./assets/Styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-modern-drawer/dist/index.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import "react-clock/dist/Clock.css";
import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ProtectedRouter from "./Helper/ProtectedRoute";
import { Loader } from "./Component/Loader";
import UpdatePassword from "./pages/UpdatePassword";
import Categories from "./pages/Categories";
import AllJobs from "./pages/AllJobs";
import Applicants from "./pages/Applicants";
import ProductsLeads from "./pages/ProductsLeads";
import ContactFormLeads from "./pages/ContactFormLeads";
import CMSPage from "./pages/CMSPage";
import SubAdmins from "./pages/SubAdmins";
import HomeCMS from "./pages/HomeCMS";
import AboutCMS from "./pages/AboutCMS";
import BeforeLoginRoute from "./Helper/BeforeLoginRoute";
import ProductsCMS from "./pages/ProductsCMS";
import SustainabilityCMS from "./pages/SustainabilityCMS";
import CareersCMS from "./pages/CareersCMS";
import ContactUsCMS from "./pages/ContactUsCMS";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Faqs from "./pages/Faqs";
import TestimonialsCRUD from "./pages/TestimonialsCRUD";
import LatestNewsCRUD from "./pages/LatestNewsCRUD";
import HomeCarouselCRUD from "./pages/HomeCarouselCRUD";
import CreateSubAdmin from "./pages/CreateSubAdmin";
import AppWrapper from "./Component/AppWrapper";
import SustainabilityCRUD from "./pages/SustainabilityCRUD";
import Filters from "./pages/Filters";
import AddEditProduct from "./pages/AddEditProduct";
import ProductDetail from "./pages/ProductDetail";
import FiltersInnerChild from "./pages/Filters/FiltersInnerChild";
import AddEditJob from "./pages/AddEditJob";
import FooterCMS from "./pages/FooterCMS";
import NewsLetters from "./pages/NewsLetters";
import ForgotPassword from "./pages/ForgotPassword";
import OtpScreen from "./pages/OtpScreen";
import ResetPassword from "./pages/ResetPassword";
import Home2ndSectionCarouselCRUD from "./pages/Home2ndSectionCarouselCRUD";

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const AllProducts = lazy(() => import("./pages/AllProducts"));
const NotFound = lazy(() => import("./pages/NotFound"));


function App() {
  return (
    <>
      <ToastContainer  />

      <BrowserRouter>
        <AppWrapper />
        <Suspense fallback={<Loader className={"mvh-100"} />}>
          <Routes>
            <Route
              path="/"
              exact
              element={<BeforeLoginRoute element={<Login />} />}
            />

            <Route
              path="/forgot-password"
              exact
              // element={<ForgotPassword />}
              element={<BeforeLoginRoute element={<ForgotPassword />} />}
            />
            <Route
              path="/otp"
              exact
              // element={<OtpScreen />}
              element={<BeforeLoginRoute element={<OtpScreen />} />}
            />
            <Route
              path="/reset-password"
              exact
              // element={<ResetPassword />}
              element={<BeforeLoginRoute element={<ResetPassword />} />}
            />

            <Route
              path="/dashboard"
              exact
              element={<ProtectedRouter element={<Dashboard />} />}
            />
            <Route
              path="/sub-admins"
              exact
              element={<ProtectedRouter element={<SubAdmins />} />}
            />

            <Route
              path="/sub-admins/create"
              exact
              element={<ProtectedRouter element={<CreateSubAdmin />} />}
            />
            <Route
              path="/sub-admins/:slug"
              exact
              element={<ProtectedRouter element={<CreateSubAdmin />} />}
            />
            <Route
              path="/all-products"
              exact
              element={<ProtectedRouter element={<AllProducts />} />}
            />
            <Route
              path="/product-detail/:slug"
              exact
              element={<ProtectedRouter element={<ProductDetail />} />}
            />
            <Route
              path="/add-product"
              exact
              element={<ProtectedRouter element={<AddEditProduct />} />}
            />
            <Route
              path="/edit-product/:slug"
              exact
              element={<ProtectedRouter element={<AddEditProduct />} />}
            />
            <Route
              path="/all-jobs"
              exact
              element={<ProtectedRouter element={<AllJobs />} />}
            />
            <Route
              path="/all-jobs/create"
              exact
              element={<ProtectedRouter element={<AddEditJob />} />}
            />
            <Route
              path="/all-jobs/:slug"
              exact
              element={<ProtectedRouter element={<AddEditJob />} />}
            />
            <Route
              path="/categories"
              exact
              element={<ProtectedRouter element={<Categories />} />}
            />
            <Route
              path="/categories"
              exact
              element={<ProtectedRouter element={<Categories />} />}
            />
            <Route
              path="/products-leads"
              exact
              element={<ProtectedRouter element={<ProductsLeads />} />}
            />
            <Route
              path="/contact-form-leads"
              exact
              element={<ProtectedRouter element={<ContactFormLeads />} />}
            />
            <Route
              path="/applicants"
              exact
              element={<ProtectedRouter element={<Applicants />} />}
            />
            <Route
              path="/filters"
              exact
              element={<ProtectedRouter element={<Filters />} />}
            />
            <Route
              path="/filter-inners/:slug"
              exact
              element={<ProtectedRouter element={<FiltersInnerChild />} />}
            />
            <Route
              path="/newsletters"
              exact
              element={<ProtectedRouter element={<NewsLetters />} />}
            />
            <Route path="/cms" exact>
              <Route
                index
                element={<ProtectedRouter element={<CMSPage />} />}
              />
              <Route
                path="home"
                element={<ProtectedRouter element={<HomeCMS />} />}
              />
              <Route
                path="home-hero-carousel"
                element={<ProtectedRouter element={<HomeCarouselCRUD />} />}
              />
              <Route
                path="home-second-section-carousel"
                element={<ProtectedRouter element={<Home2ndSectionCarouselCRUD />} />}
              />
              <Route
                path="about-us"
                element={<ProtectedRouter element={<AboutCMS />} />}
              />
              <Route
                path="products"
                element={<ProtectedRouter element={<ProductsCMS />} />}
              />
              <Route
                path="sustainability"
                element={<ProtectedRouter element={<SustainabilityCMS />} />}
              />
              <Route
                path="sustainability-crud"
                element={<ProtectedRouter element={<SustainabilityCRUD />} />}
              />
              <Route
                path="careers"
                element={<ProtectedRouter element={<CareersCMS />} />}
              />
              <Route
                path="contact-us"
                element={<ProtectedRouter element={<ContactUsCMS />} />}
              />
              <Route
                path="faqs"
                element={<ProtectedRouter element={<Faqs />} />}
              />
              <Route
                path="testimonials"
                element={<ProtectedRouter element={<TestimonialsCRUD />} />}
              />
              <Route
                path="latest-news"
                element={<ProtectedRouter element={<LatestNewsCRUD />} />}
              />
              <Route
                path="terms-and-conditions"
                element={<ProtectedRouter element={<TermsAndConditions />} />}
              />
              <Route
                path="privacy-policy"
                element={<ProtectedRouter element={<PrivacyPolicy />} />}
              />
              <Route
                path="footer"
                element={<ProtectedRouter element={<FooterCMS />} />}
              />
            </Route>

            <Route
              path="/update-password"
              exact
              element={<ProtectedRouter element={<UpdatePassword />} />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
