import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import SubHeader from "../../Component/SubHeader";
import TableSkeleton from "../../Component/TableSkeleton";
import useDebounce from "../../CustomHooks/useDebounce";
import { FileDownloadFromUrl } from "../../Helper/FileDownloadFromLink";
import {
  BaseURL,
  apiHeader,
  imageUrl,
  recordsLimit,
} from "../../config/apiUrl";
import ViewJobApplicantFormModal from "../../modals/ViewJobApplicantFormModal";
import classes from "./Applicants.module.css";

const Applicants = () => {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(20);

  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState("");
  const searchText = useDebounce(search, 500);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  async function getData(pg = page) {
    const url = BaseURL(
      `job/applications/all?&page=${pg}&limit=${recordsLimit}`
    );
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
      setLoading(false);
    }
  }

  useEffect(() => {
    setPage(1);
    getData(1);
  }, [searchText]);

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 330px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12}>
                <SubHeader
                  title={"Applicants"}
                  search={search}
                  setSearch={setSearch}
                  status={status}
                  setStatus={setStatus}
                />
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div class="table100 ver1 m-b-110">
                  <div class="table100-head">
                    <table>
                      <thead>
                        <tr class="row100 head">
                          <th
                            class="cell100 column1"
                            style={{ width: "20%", textAlign: "start" }}
                          >
                            Applicants Name
                          </th>
                          <th
                            class="cell100 column2"
                            style={{ width: "20%", textAlign: "start" }}
                          >
                            Email
                          </th>

                          <th
                            class="cell100 column3"
                            style={{ width: "20%", textAlign: "start" }}
                          >
                            Mobile No#
                          </th>

                          <th
                            class="cell100 column4"
                            style={{ width: "20%", textAlign: "start" }}
                          >
                            Download CV
                          </th>

                          <th
                            class="cell100 column5"
                            style={{ width: "20%", textAlign: "center" }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className={`table100-body js-pscroll ps ps--active-y`}>
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr
                                class="row100 body"
                                style={
                                  {
                                    // backgroundColor:
                                    // selectedItem?._id == item?._id && "#E5F5FF",
                                  }
                                }
                              >
                                <td
                                  class="cell100 column1"
                                  style={{ width: "20%", textAlign: "left" }}
                                >
                                  {/* {index + 1 + (page - 1) * recordsLimit} */}
                                  {item?.firstName + " " + item?.lastName}
                                </td>
                                <td
                                  class="cell100 column2"
                                  style={{
                                    width: "20%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.email}
                                  </div>
                                </td>

                                <td
                                  class="cell100 column4"
                                  style={{ width: "20%", textAlign: "left" }}
                                >
                                  {item?.mobileNumber}
                                </td>

                                <td
                                  class="cell100 column4"
                                  style={{ width: "20%", textAlign: "left" }}
                                >
                                  <span
                                    onClick={() => {
                                      FileDownloadFromUrl(
                                        imageUrl(item?.documents?.[0]),
                                        `${
                                          item?.firstName + " " + item?.lastName
                                        }-CV`
                                      );
                                    }}
                                    style={{
                                      color: "blue",
                                    }}
                                    className="c-p"
                                  >
                                    Download
                                  </span>
                                </td>
                                <td
                                  class="cell100 column5"
                                  style={{ width: "20%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <Button
                                      variant="primary"
                                      className={classes.viewDetailBtn}
                                      label={"View Detail"}
                                      onClick={() => {
                                        setSelectedItem(item);
                                        setShowModal("view");
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Applicants Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e);
                    }}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
        {showModal == "view" && (
          <ViewJobApplicantFormModal
            show={showModal == "view"}
            setShow={setShowModal}
            data={selectedItem}
          />
        )}
      </div>
    </>
  );
};
export default Applicants;
