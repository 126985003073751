import React from "react";

const ShowMoreShowLessText = ({ text, visibility = 30}) => {
  const [isshowingMore, setIsShowingMore] = React.useState(false);

  return (
    <>
      {text?.substring(0, isshowingMore ? text.length : visibility)}
      {text?.length > visibility && !isshowingMore && "..."}{" "}
      {text?.length > visibility && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            setIsShowingMore((p) => !p);
          }}
          style={{
            color: "#1c002a",
            fontWeight: "700",
            cursor: "pointer",
          }}
        >
          {" "}
          {isshowingMore ? "Show Less" : "Show More"}
        </span>
      )}
    </>
  );
};

export default ShowMoreShowLessText;
