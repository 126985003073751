import React, { useState } from "react";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddCategoryModal.module.css";
const AddCategoryModal = ({ show, setShow, isApiCall, onClick, isEdit }) => {
  const [category, setCategory] = useState("" || isEdit?.name);
  return (
    <div>
      <ModalSkeleton
        setShow={setShow}
        show={show}
        width={"700px"}
        header={`${isEdit ? "Edit" : "Add"} Category`}
        borderRadius={"20px"}

        //   showCloseIcon
      >
        <div className={classes.main}>
          <div className={classes.body}>
            <div className={classes.inputDiv}>
              <Input
                label={"Category Name"}
                placeholder="Enter Category Name"
                value={category}
                setter={setCategory}
              />
            </div>
          </div>
          <div className={"text-center my-4"}>
            <Button
              disabled={isApiCall}
              label={isApiCall ? "Please Wait..." : "Submit"}
              onClick={() =>
                onClick(
                  {
                    category,
                  },
                  isEdit ? true : false
                )
              }
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default AddCategoryModal;
