import React, { useEffect, useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import { TextArea } from "../../Component/TextArea";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import classes from "./AddLatestNewsModal.module.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import UploadImageBox from "../../Component/UploadImageBox";
import moment from "moment";
import QuillInput from "../../Component/QuillInput";

const AddLatestNewsModal = ({ show, setShow, loading, onClick, data }) => {
  const [photo, setPhoto] = useState(null);
  const [date, setDate] = useState("");
  const [newsTopic, setNewsTopic] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (data) {
      setDate(moment(data?.date).format("YYYY-MM-DD"));
      setNewsTopic(data?.newsTopic);
      setPhoto(data?.photo);
      setDescription(data?.description);
    }
  }, [data]);

  return (
    <>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        header={data ? "Edit Latest News" :"Add Latest News"}
        borderRadius={20}
        modalClass={classes.modalClass}
      >
        <Row className={classes.row}>
          <Col md={12}>
            <UploadImageBox label={"Photo (392 x 450)"} setter={setPhoto} state={photo} />
          </Col>
          <Col md={12}>
            <QuillInput
              label={"Title"}
              setter={setNewsTopic}
              value={newsTopic}
              placeholder={"Enter Title"}
              quillClass={classes.quillInput}
              
            />
          </Col>
          <Col md={12}>
            <Input
              label={"Date"}
              setter={setDate}
              value={date}
              type={'date'}
              placeholder={"Select Date"}
            />
          </Col>
          <Col md={12}>
            <TextArea
              label={"Description"}
              setter={setDescription}
              value={description}
              placeholder={"Enter Description"}
            />
          </Col>
          <Col md={12}>
            <div className={classes.btnsDiv}>
              <Button
                label={loading ? "Wait..." : data ? "Update" : "Submit"}
                onClick={() => {
                  const params = {
                    photo,
                    newsTopic,
                    date,
                    description,
                  };
                  for (let i in params) {
                    if (!params[i]) {
                      return toast.error("Please fill all the fields");
                    }
                  }
                  onClick(
                    params,
                  );
                }}
                disabled={loading}
              />
            </div>
          </Col>
        </Row>
      </ModalSkeleton>
    </>
  );
};

export default AddLatestNewsModal;
