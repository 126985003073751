import React from "react";
import { Col, Row } from "react-bootstrap";
import classes from "./SubHeader.module.css";
import SearchInput from "../SearchInput";
import { DropDown } from "../DropDown/DropDown";
import { Button } from "../Button/Button";
import { activeDeactiveStatusoptions } from "../../constant/commonData";

const SubHeader = ({
  title,
  search,
  setSearch,
  setStatus,
  status,
  onAddClick,
  btnLabel,
  options = [],
}) => {
  return (
    <Row className={classes.row}>
      <Col md={4}>
        <h2>{title}</h2>
      </Col>
      <Col md={8}>
        <style>{`
        .custom_drop__placeholder{
         color:var(--white-color) !important;
        }
        .custom_drop__single-value {
          color: var(--white-color) !important;
        }
        `}</style>
        <div className={classes.options_div}>
          {setSearch && (
            <div
              style={{
                marginRight: !options?.length && "20px",
              }}
            >
              <SearchInput value={search} setter={setSearch} />
            </div>
          )}
          {!!options?.length && (
            <DropDown
              classNamePrefix={"custom_drop"}
              value={status}
              setter={setStatus}
              placeholder={"Filter"}
              options={activeDeactiveStatusoptions}
              customStyle={{ color: "var(--white-color)", minWidth: "130px" }}
            />
          )}
          {btnLabel && (
            <Button variant="primary" label={btnLabel} onClick={onAddClick} />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default SubHeader;
