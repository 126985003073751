import React, { useEffect, useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import classes from "./EditSustainabilityModal.module.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import UploadImageBox from "../../Component/UploadImageBox";
import QuillInput from "../../Component/QuillInput";

const EditSustainabilityModal = ({ show, setShow, loading, onClick, data }) => {
  const [photo, setPhoto] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coverPhoto, setCoverPhoto] = useState(null);

  useEffect(() => {
    if (data) {
      setTitle(data?.title);
      setPhoto(data?.image);
      setDescription(data?.description);
      setCoverPhoto(data?.coverPhoto);
    }
  }, [data]);

  return (
    <>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        header={data ? "Edit Sustainability" : "Add Sustainability"}
        borderRadius={20}
        modalClass={classes.modalClass}
      >
        <Row className={classes.row}>
          <Col md={12}>
            <UploadImageBox label={"Photo (414 x 505)"} setter={setPhoto} state={photo} />
          </Col>
          <Col md={12}>
            <UploadImageBox label={"Hero Section Photo (1903 x 600)"} setter={setCoverPhoto} state={coverPhoto} />
          </Col>
          <Col md={12}>
            <Input
              label={"Title"}
              setter={setTitle}
              value={title}
              placeholder={"Enter Title"}
            />
          </Col>
         
          <Col md={12}>
            <QuillInput
              label={"Description"}
              setter={setDescription}
              value={description}
              placeholder={"Enter Description"}
              quillClass={classes.quillInput}
            />
          </Col>
          <Col md={12}>
            <div className={classes.btnsDiv}>
              <Button
                label={loading ? "Wait..." : data ? "Update" : "Submit"}
                onClick={() => {
                  const params = {
                    image:photo,
                    coverPhoto,
                    title,
                    description,
                  };
                  for (let i in params) {
                    if (!params[i]) {
                      return toast.error("Please fill all the fields");
                    }
                  }
                  onClick(params);
                }}
                disabled={loading}
              />
            </div>
          </Col>
        </Row>
      </ModalSkeleton>
    </>
  );
};

export default EditSustainabilityModal;
