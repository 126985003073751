import React, { useRef } from "react";
import { MdUpload, MdModeEdit, MdClose } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { imageUrl } from "../../config/apiUrl";
import classes from "./UploadImageBox.module.css";
import { BsImages } from "react-icons/bs";
import { toast } from "react-toastify";

function UploadImageBox({
  state,
  setter,
  label,
  edit = true,
  onDelete,
  onClose,
  isCloseable,
  hideDeleteIcon = false,
  imgClass,
  containerClass = "",
  onEdit,
  allowdTypes = ["image/jpg, image/jpeg, image/png"],
  FallbackIcon = BsImages,
  isMultiple = false,
}) {
  const inputRef = useRef(null);
  const Icon = (e) =>
    FallbackIcon ? <FallbackIcon {...e} /> : <BsImages {...e} />;
  return (
    <>
      {label && <label className={classes.label}>{label}</label>}

      <div className={`${classes.box} ${containerClass}`}>
        <div className={classes.uploadImageBox}>
          {/* Close Icon */}
          {isCloseable && (
            <span className={classes.closeIcon} onClick={onClose}>
              <MdClose />
            </span>
          )}
          {state?.name || typeof state == "string" ? (
            <div className={classes.imageUploaded}>
              {allowdTypes?.map((e) => e?.split("/")[0])?.includes("image") ? (
                <img
                  src={
                    typeof state == "object"
                      ? URL.createObjectURL(state)
                      : state?.includes("https://")
                      ? state
                      : imageUrl(state)
                  }
                  className={imgClass ? imgClass : ""}
                  alt=""
                />
              ) : (
                <video
                  src={
                    typeof state == "object"
                      ? URL.createObjectURL(state)
                      : state?.includes("https://")
                      ? state
                      : imageUrl(state)
                  }
                  controls
                />
              )}
              <div className={classes.editAndDelete}>
                {edit && (
                  <>
                    {hideDeleteIcon && (
                      <div className={classes.icon} onClick={onDelete}>
                        <RiDeleteBinLine />
                      </div>
                    )}
                    <div
                      className={classes.icon}
                      onClick={() => {
                        inputRef.current.click();
                        onEdit && onEdit();
                      }}
                    >
                      <MdModeEdit />
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className={classes.uploadBox}>
              {Icon({ className: classes.icon })}

              <div
                className={classes.uploadIcon}
                onClick={() => inputRef.current.click()}
              >
                <MdUpload />
              </div>
            </div>
          )}
        </div>

        <input
          hidden
          type={"file"}
          ref={inputRef}
          onChange={(e) => {
            if (!allowdTypes.find((a) => a.includes(e.target.files[0]?.type))) {
              return toast.error(
                `Invalid File Type, Only ${allowdTypes
                  ?.map((a) => a.split("/")[1])
                  ?.join(", ")} files are allowed`
              );
            } else {
              setter(isMultiple ? e.target.files : e.target.files[0]);
            }
          }}
        />
      </div>
    </>
  );
}

export default UploadImageBox;
