import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import SubHeader from "../../Component/SubHeader";
import TableSkeleton from "../../Component/TableSkeleton";
import { BaseURL, apiHeader, recordsLimit } from "../../config/apiUrl";
import { activeDeactiveStatusoptions } from "../../constant/commonData";
import AddCategoryModal from "../../modals/AddCategoryModal";
import classes from "./Categories.module.css";
import useDebounce from "../../CustomHooks/useDebounce";

const Categories = () => {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(20);
  const [btnLoading, setBtnLoading] = useState("");

  const [status, setStatus] = useState(activeDeactiveStatusoptions[0]);
  const [search, setSearch] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [isApiCall, setIsApiCall] = useState(false);

  const searchDebounce = useDebounce(search, 500);

  const handleStatus = async (sts, id) => {
    const params = {
      status: sts,
    };
    setBtnLoading(id);
    const response = await Patch(
      BaseURL(`category/active-deactive/${id}`),
      params,
      apiHeader(accessToken)
    );
    if (response !== undefined) {
      const newData = [...data];
      if (status?.value == "all") {
        newData?.splice(
          newData?.findIndex((item) => item?._id == response?.data?.data?._id),
          1,
          response?.data?.data
        );
      } else {
        newData?.splice(
          newData?.findIndex((item) => item?._id == response?.data?.data?._id),
          1
        );
      }

      setData(newData);
      toast.success(
        `Category has been ${response?.data?.data?.isActive ? "activated" : "deactivated"} successfully`
      );
    }
    setBtnLoading("");
  };

  async function getData(pg = page, sts = status?.value) {
    const url = BaseURL(
      `category/all?status=${
        sts ?? "all"
      }&page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
      setLoading(false);
    }
  }

  useEffect(() => {
    setPage(1);
    getData(1);
  }, [searchDebounce]);

  const addAndEditCategory = async (data, isEditType) => {
    const params = {
      name: data?.category,
    };
    // validation

    if (!data?.category) {
      toast.error("Please Enter Category Name");
      return;
    }

    const apiType = isEditType ? Patch : Post;
    const url = isEditType ? `category/${isEdit?._id}` : `category`;

    setIsApiCall(true);
    const response = await apiType(
      BaseURL(url),
      params,
      apiHeader(accessToken)
    );
    setIsApiCall(false);
    if (response) {
      if (isEditType) {
        setData((prev) => {
          const newData = [...prev];
          newData?.splice(
            newData?.findIndex(
              (item) => item?._id == response?.data?.data?._id
            ),
            1,
            response?.data?.data
          );
          return newData;
        });
      } else {
        setData((prev) => [response?.data?.data, ...prev]);
      }
      setIsOpenModal(false);
      toast.success("Category Added Successfully");
    }
  };

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 330px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12}>
                <SubHeader
                  title={"Categories"}
                  search={search}
                  setSearch={setSearch}
                  status={status}
                  setStatus={(e) => {
                    setStatus(e);
                    setPage(1);
                    getData(1, e?.value);
                  }}
                  onAddClick={() => setIsOpenModal(true)}
                  btnLabel="Add New Category"
                  options={activeDeactiveStatusoptions}
                />
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div class="table100 ver1 m-b-110">
                  <div class="table100-head">
                    <table>
                      <thead>
                        <tr class="row100 head">
                          <th
                            class="cell100 column1"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            S/No
                          </th>
                          <th
                            class="cell100 column2"
                            style={{ width: "25%", textAlign: "start" }}
                          >
                            Name
                          </th>

                          <th
                            class="cell100 column3"
                            style={{ width: "20%", textAlign: "start" }}
                          >
                            Created At
                          </th>

                          <th
                            class="cell100 column4"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Status
                          </th>

                          <th
                            class="cell100 column5"
                            style={{ width: "25%", textAlign: "center" }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className={`table100-body js-pscroll ps ps--active-y`}>
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr class="row100 body">
                                <td
                                  class="cell100 column1"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  class="cell100 column2"
                                  style={{
                                    width: "25%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.name}
                                  </div>
                                </td>

                                <td
                                  class="cell100 column4"
                                  style={{ width: "20%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY hh:mm a"
                                    )}
                                  </div>
                                </td>

                                <td
                                  class="cell100 column4"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  {item?.isActive ? "Active" : "Deactive"}
                                </td>
                                <td
                                  class="cell100 column5"
                                  style={{ width: "25%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={"Edit"}
                                      onClick={() => {
                                        setIsOpenModal(true);
                                        setIsEdit(item);
                                      }}
                                    />

                                    <Button
                                      className={classes.viewDetailBtn}
                                      variant="primary"
                                      label={
                                        btnLoading === item?._id
                                          ? "Wait..."
                                          : !item?.isActive
                                          ? "Activate"
                                          : "Deactivate"
                                      }
                                      onClick={() => {
                                        handleStatus(
                                          item?.isActive ? false : true,
                                          item?._id
                                        );
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Categories Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e);
                    }}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
      </div>
      {isOpenModal && (
        <AddCategoryModal
          isApiCall={isApiCall}
          show={isOpenModal}
          setShow={() => {
            setIsOpenModal(false);
            setIsEdit(null);
          }}
          getData={getData}
          onClick={addAndEditCategory}
          isEdit={isEdit}
        />
      )}
    </>
  );
};
export default Categories;
