/*eslint-disable*/
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BaseURL, apiUrl } from "../../config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import { updateUser } from "../../store/auth/authSlice";

function AppWrapper() {
  const { accessToken, isLogin } = useSelector((state) => state?.authReducer);

  const dispatch = useDispatch();

  const getMe = async () => {
    const url = BaseURL("users/me");
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      const apiUser = response?.data?.data?.user;
      dispatch(updateUser(apiUser));
    }
  };

  useEffect(() => {
    if (isLogin) {
      getMe();
    }
  }, [isLogin]);
}

export default AppWrapper;
