import React from "react";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../ModalSkeleton";
import styles from "./ViewContactFormModal.module.css";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";

const ViewContactFormModal = ({ show, setShow, data }) => {
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"650px"}
      header={"View Contact Form Lead"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={6}>
          <label>Name:</label>
          <p>{data?.name}</p>
        </Col>
        <Col md={6}>
          <label>Phone:</label>
          <p>{data?.phone ?? "----"}</p>
        </Col>
        <Col md={6}>
          <label>Email:</label>
          <p>{data?.email}</p>
        </Col>
        <Col md={6}>
          <label>Subject:</label>
          <p>{data?.subject ?? '----'}</p>
        </Col>

        <Col md={12}>
          <label>Description:</label>
          <ShowMoreShowLessText text={data?.message} visibility={200} />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default ViewContactFormModal;
