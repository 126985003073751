import React, { useEffect, useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import { TextArea } from "../../Component/TextArea";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import classes from "./AddSecondSectionCarouselModal.module.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import UploadImageBox from "../../Component/UploadImageBox";
import QuillInput from "../../Component/QuillInput";

const AddSecondSectionCarouselModal = ({
  show,
  setShow,
  loading,
  onClick,
  data,
}) => {
  const [photo, setPhoto] = useState(null);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (data) {
      setTitle(data?.title);
      setPhoto(data?.image);
    }
  }, [data]);

  return (
    <>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        header={
          data ? "Edit Home Second Section" : "Add Home Second Section"
        }
        borderRadius={20}
        modalClass={classes.modalClass}
      >
        <Row className={classes.row}>
          <Col md={12}>
            <UploadImageBox
              label={"Photo (200 x 200)"}
              setter={setPhoto}
              state={photo}
            />
          </Col>
          <Col md={12}>
            <Input
              label={"Title"}
              setter={setTitle}
              value={title}
              placeholder={"Enter Title"}
            />
          </Col>

          <Col md={12}>
            <div className={classes.btnsDiv}>
              <Button
                label={loading ? "Wait..." : data ? "Update" : "Submit"}
                onClick={() => {
                  const params = {
                    image: photo,
                    title,
                  };
                  for (let i in params) {
                    if (!params[i]) {
                      return toast.error("Please fill all the fields");
                    }
                  }
                  onClick(params);
                }}
                disabled={loading}
              />
            </div>
          </Col>
        </Row>
      </ModalSkeleton>
    </>
  );
};

export default AddSecondSectionCarouselModal;
