import Parser from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Delete, Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import SubHeader from "../../Component/SubHeader";
import TableSkeleton from "../../Component/TableSkeleton";
import useDebounce from "../../CustomHooks/useDebounce";
import {
  BaseURL,
  CreateFormData,
  apiHeader,
  imageUrl,
  recordsLimit,
} from "../../config/apiUrl";
import AddLatestNewsModal from "../../modals/AddLatestNewsModal";
import AreYouSureModal from "../../modals/AreYouSureModal";
import ViewLatestNewsModal from "../../modals/ViewLatestNewsModal";
import classes from "./LatestNewsCRUD.module.css";

const LatestNewsCRUD = () => {
  const { accessToken } = useSelector((state) => state.authReducer);
  const [showModal, setShowModal] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState("get");
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const debouncedSearch = useDebounce(search, 500);

  const getData = async (pg = page) => {
    const url = BaseURL(
      `latest-news?page=${pg}&limit=${recordsLimit}&search=${search}`
    );
    setIsLoading("get");

    const response = await Get(url, accessToken);
    setIsLoading("");

    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
    }
  };
  useEffect(() => {
    setPage(1);
    getData(1);
  }, [debouncedSearch]);
  // Add Update FAQ

  const addUpdateItem = async ({ data: body }) => {
    const id = selectedItem?._id;
    setIsLoading("add");
    const apiFunc = id ? Patch : Post;
    const url = BaseURL(`latest-news/${id ?? "/create"}`);
    const formData = await CreateFormData(body);
    const response = await apiFunc(url, formData, apiHeader(accessToken));
    setIsLoading("");

    if (response) {
      const newData = [...data];
      if (id) {
        const index = newData?.findIndex((item) => item._id == id);
        newData?.splice(index, 1, response?.data?.data);
        toast.success("Latest News updated successfully");
      } else {
        newData?.unshift(response?.data?.data);
        toast.success("Latest News added successfully");
        setTotalResults((p) => ++p);
      }
      setData(newData);
      setShowModal("");
    }
  };
  // Delete

  const deleteItem = async () => {
    setIsLoading("delete");
    const url = BaseURL(`latest-news/${selectedItem?._id}`);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsLoading("");

    if (response) {
      const newData = [...data];
      const index = newData.findIndex(
        (item) => item?._id === selectedItem?._id
      );
      newData?.splice(index, 1);
      setData(newData);
      toast.success("Latest News deleted successfully");
      setShowModal("");
    }
  };

  return (
    <SideBarSkeleton search={search} setSearch={setSearch}>
      <style>{`
      .table100-body{
        height:calc(100vh - 330px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <Container className={classes.main}>
        {/* table */}
        <Row className={classes.row}>
          <Col md={12}>
            <SubHeader
              title={"Latest News"}
              search={search}
              setSearch={setSearch}
              btnLabel="Add Latest News"
              onAddClick={() => {
                setSelectedItem(null);
                setShowModal("add");
              }}
            />
          </Col>
          <Col md={12} className={classes.tableMain}>
            <div class="table100 ver1 m-b-110">
              <div class="table100-head">
                <table>
                  <thead>
                    <tr class="row100 head">
                      <th
                        class="cell100 column1"
                        style={{ width: "10%", textAlign: "start" }}
                      >
                        S/No
                      </th>
                      <th
                        class="cell100 column2"
                        style={{ width: "10%", textAlign: "start" }}
                      >
                        Photo
                      </th>
                      <th
                        class="cell100 column2"
                        style={{ width: "15%", textAlign: "start" }}
                      >
                        Title
                      </th>
                      <th
                        class="cell100 column2"
                        style={{ width: "15%", textAlign: "start" }}
                      >
                        Date
                      </th>
                      <th
                        class="cell100 column2"
                        style={{ width: "20%", textAlign: "start" }}
                      >
                        Description
                      </th>
                      <th
                        class="cell100 column3"
                        style={{ width: "10%", textAlign: "start" }}
                      >
                        Created At
                      </th>

                      <th
                        class="cell100 column5"
                        style={{ width: "20%", textAlign: "center" }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              {isLoading == "get" ? (
                <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
              ) : (
                <div className={`table100-body js-pscroll ps ps--active-y`}>
                  <table>
                    <tbody>
                      {data?.length > 0 ? (
                        data?.map((item, index) => (
                          <tr class="row100 body">
                            <td
                              class="cell100 column1"
                              style={{ width: "10%", textAlign: "left" }}
                            >
                              {index + 1}
                            </td>
                            <td
                              class="cell100 column2"
                              style={{
                                width: "10%",
                                textAlign: "left",
                              }}
                            >
                              <div className={classes?.imgDiv}>
                                <img src={imageUrl(item?.photo)} />
                              </div>
                            </td>
                            <td
                              class="cell100 column2"
                              style={{
                                width: "15%",
                                textAlign: "left",
                              }}
                            >
                              <div className={"text1Line"}>
                                {Parser(item?.newsTopic)}
                              </div>
                            </td>
                            <td
                              class="cell100 column2"
                              style={{
                                width: "15%",
                                textAlign: "left",
                              }}
                            >
                              <div className={"text1Line"}>
                                {moment(item?.date).format("DD MMM, YYYY")}
                              </div>
                            </td>
                            <td
                              class="cell100 column2"
                              style={{
                                width: "20%",
                                textAlign: "left",
                              }}
                            >
                              <div className={"text1Line"}>
                                {item?.description}
                              </div>
                            </td>

                            <td
                              class="cell100 column4"
                              style={{ width: "10%", textAlign: "left" }}
                            >
                              <div className={classes.descCol}>
                                {moment(item?.createdAt).format(
                                  "DD/MM/YYYY hh:mm a"
                                )}
                              </div>
                            </td>

                            <td
                              class="cell100 column5"
                              style={{ width: "20%" }}
                            >
                              <div className={classes.actions_btn}>
                                <Button
                                  className={classes.viewDetailBtn}
                                  label={"View"}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setShowModal("view");
                                  }}
                                />
                                <Button
                                  className={classes.viewDetailBtn}
                                  label={"Edit"}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setShowModal("add");
                                  }}
                                />
                                <Button
                                  className={classes.viewDetailBtn}
                                  label={"Delete"}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setShowModal("delete");
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoData
                          text={"No Latest News Found"}
                          className={classes?.noData}
                        />
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Col>
          <Col md={12} className={classes.paginationDiv}>
            {!!data?.length && (
              <PaginationComponent
                totalPages={Math.ceil(totalResults / recordsLimit)}
                setCurrentPage={(e) => {
                  setPage(e);
                  getData(e);
                }}
                currentPage={page}
              />
            )}
          </Col>
        </Row>
      </Container>
      {showModal == "view" && (
        <ViewLatestNewsModal
          show={showModal == "view"}
          setShow={setShowModal}
          data={selectedItem}
        />
      )}
      {showModal == "add" && (
        <AddLatestNewsModal
          show={showModal == "add"}
          setShow={setShowModal}
          loading={isLoading == "add"}
          data={selectedItem}
          onClick={async (e, id) => {
            await addUpdateItem({ data: e });
          }}
        />
      )}
      {showModal == "delete" && (
        <AreYouSureModal
          show={showModal == "delete"}
          setShow={setShowModal}
          isApiCall={isLoading == "delete"}
          subTitle={"Are you sure you want to delete this Latest News?"}
          onClick={async () => {
            await deleteItem();
          }}
        />
      )}
    </SideBarSkeleton>
  );
};

export default LatestNewsCRUD;
