import React from "react";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../ModalSkeleton";
import styles from "./FAQViewModal.module.css";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";

const FAQViewModal = ({ show, setShow, data }) => {
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"View FAQ"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={12}>
          <label>Question:</label>
          <parseFloat>{data?.question}</parseFloat>
        </Col>
        <Col md={12}>
          <label>Answer:</label>
          <ShowMoreShowLessText text={data?.answer} visibility={200}/>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default FAQViewModal;
