import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import AddTags from "../../Component/AddTags";
import { Button } from "../../Component/Button/Button";
import { DropDown } from "../../Component/DropDown/DropDown";
import FileUploadDropzone from "../../Component/FileUploadDropzone";
import { Input } from "../../Component/Input/Input";
import { Loader } from "../../Component/Loader";
import SidebarSkeleton from "../../Component/SideBarSkeleton";
import { TextArea } from "../../Component/TextArea";
import {
  BaseURL,
  CreateFormData,
  apiHeader,
  formRegEx,
  formRegExReplacer,
  getFormattedParams,
} from "../../config/apiUrl";
import classes from "./AddEditProduct.module.css";
import validator from "validator";

function AddEditProduct() {
  const navigate = useNavigate();
  const slug = useParams()?.slug;
  const { accessToken } = useSelector((state) => state?.authReducer);
  // Filters GET State
  const [filtersOptions, setFiltersOptions] = useState([]);
  // Filters Options
  const [optionsFilter, setOptionsFilter] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [deleteImages, setDeleteImages] = useState([]);
  const [formFields, setFormFields] = useState({
    name: "",
    code: "",
    capacity: "",
    height: "",
    majorAxis: "",
    minorAxis: "",
    material: "",
    weight: "",
    handle: "",
    description: "",
    advantages: "",
    category: "",
    image360: "",
    filters: [],
    images: [],
  });
  const [usageArea, setUsageArea] = useState([]);

  const filterFilteringOptions = (e, filterArr) => {
    let newOptions =
      Array.isArray(filterArr) &&
      filterArr
        .map((item) => {
          if (item?.category?._id == e?._id) {
            return {
              label: item?.name,
              _id: item?._id,
              options: item?.subFilters.map((item1) => {
                return {
                  label: item1?.name,
                  _id: item1?._id,
                };
              }),
            };
          }
        })
        .filter(Boolean);

    setOptionsFilter(newOptions);
    return newOptions;
  };

  const changeFieldsNames = {
    capacity: "Brimful Capacity (ml)",
    height: "Overall Height (mm)",
    majorAxis: "Major Axis Dia D1 (mm)",
    minorAxis: "Minor Axis Dia D2 (mm)",
    weight: "Total Weight (gm)",
  };

  const handleSubmit = async () => {
    const params = {
      ...formFields,
      filters: formFields?.filters?.map((item) => item?._id),
      category: formFields?.category?._id,
      usageArea: usageArea.map((item) => item),
    };

    for (let key in params) {
      if (Array.isArray(params[key])) {
        if (key == "images") {
          if (params[key]?.length < 3) {
            return toast.error(`Please upload at least 3 images`);
          }
        } else {
          if (params[key]?.length == 0) {
            return toast.error(
              `Please fill the ${getFormattedParams(key)} field`
            );
          }
        }
      }
      if (!params[key]) {
        return toast.error(
          `Please fill the ${getFormattedParams(
            changeFieldsNames?.[key] ?? key
          )} field`
        );
      }
    }
    if (!validator?.isURL(params?.image360, { protocols: ["https"] })) {
      return toast.error(`Please enter valid image360 URL`);
    }

    const formData = CreateFormData(params);
    deleteImages?.map((e, index) =>
      formData.append(`deleteImages${deleteImages?.length > 1 ? "" : "[]"}`, e)
    );

    const apiType = slug ? Patch : Post;
    setLoading("updating");
    const response = await apiType(
      BaseURL(slug ? `product/${slug}` : `product/create`),
      formData,
      apiHeader(accessToken)
    );
    setLoading("");

    if (response) {
      toast.success(`Product ${slug ? "edited" : "created"} successfully`);
      navigate("/all-products");
    }
  };

  async function getData() {
    const url = BaseURL(`filter/all`);
    setLoading("loading");
    const [response, response1] = await Promise.allSettled([
      Get(url, accessToken),
      Get(BaseURL(`category/all`), accessToken),
    ]);
    setLoading("");

    if (response?.status == "fulfilled" || response1?.status == "fulfilled") {
      setFiltersOptions({
        filters: response?.value?.data?.data,
        category: response1?.value?.data?.data,
      });
      if (slug) {
        getProdcutData(response?.value?.data?.data);
      }
    }
  }

  const getProdcutData = async (filterData) => {
    setLoading("loading");
    const response = await Get(BaseURL(`product/detail/${slug}`), accessToken);
    setLoading("");

    if (response) {
      const { data } = response?.data;
      const { usageArea, filters, category } = data;

      filterFilteringOptions(category, filterData);

      // const selectedFilters = [];
      // await filters?.forEach((item) => {
      //   const findIndex = selectedFilters?.findIndex(
      //     (item1) => item1?._id == item?.filter?._id
      //   );
      //   if (findIndex != -1) {
      //     const findItem = JSON.parse(
      //       JSON.stringify(selectedFilters[findIndex])
      //     );
      //     findItem.subFilters.push(item);
      //     selectedFilters?.splice(findIndex, 1, findItem);
      //   } else {
      //     const d = filterData?.find(
      //       (item1) => item1?._id == item?.filter?._id
      //     );
      //     const items = d?.subFilters?.filter((e) => e?._id == item?._id);
      //     console.log(items, "items",d);
      //     selectedFilters.push({
      //       ...d,
      //       label:d?.name,
      //       options: items?.map(e=>({...e, label:e?.name})),
      //     });
      //   }

      // });

      setUsageArea(usageArea);
      setFormFields({
        name: data?.name,
        code: data?.code,
        capacity: data?.capacity,
        height: data?.height,
        majorAxis: data?.majorAxis,
        minorAxis: data?.minorAxis,
        material: data?.material,
        weight: data?.weight,
        handle: data?.handle,
        order: data?.order ?? "",
        description: data?.description,
        advantages: data?.advantages,
        image360: data?.image360,
        images: data?.images,
        filters: data?.filters?.map((item) => ({ ...item, label: item?.name })),
        category: data?.category,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const updateValue = (key, value) => {
    setFormFields({ ...formFields, [key]: value });
  };

  return (
    <SidebarSkeleton>
      <Container className={classes.main}>
        {isLoading == "loading" ? (
          <Loader height={"100vh"} />
        ) : (
          <Row>
            <Col md={12}>
              <div className={classes.pageHeader}>
                <h2>{slug ? "Update Product" : "Add Product"}</h2>
              </div>
            </Col>
            <Col md={12}>
              <div className={classes.boxShadow__wrapper}>
                <Row className={classes.formRow}>
                  {Object.entries(formFields).map(([key, value]) => {
                    // validation types
                    let typeArray = [
                      "capacity",
                      "height",
                      "majorAxis",
                      "minorAxis",
                      "weight",
                    ];
                    let textAreaTypes = ["description", "advantages"];
                    let renderLabel =
                      changeFieldsNames?.[key] ?? getFormattedParams(key);
                    return (
                      <>
                        {key == "filters" || key == "category" ? (
                          <Col
                            md={6}
                            sm={12}
                            className={classes.input__wrapper}
                          >
                            <DropDown
                              label={renderLabel}
                              value={formFields?.[key]}
                              setter={(e) => {
                                if (key == "category") {
                                  filterFilteringOptions(
                                    e,
                                    filtersOptions?.filters
                                  );
                                  setFormFields({
                                    ...formFields,
                                    filters: null,
                                    [key]: e,
                                  });
                                } else {
                                  updateValue(key, e);
                                }
                              }}
                              
                              placeholder={key}
                              options={
                                key == "category"
                                  ? filtersOptions?.[key]
                                  : optionsFilter
                              }
                              labelClassName={classes.label}
                              optionLabel={key == "category" ? "name" : "label"}
                              optionValue={"_id"}
                              isMulti={key == "category" ? false : true}
                              customStyle={{
                                backgroundColor: "#fff",
                                border: "1px solid var(--7070-clr)",
                              }}
                            />
                          </Col>
                        ) : textAreaTypes?.includes(key) ? (
                          <Col md={12} className={classes.input__wrapper}>
                            <TextArea
                              label={renderLabel}
                              value={value}
                              setter={(e) => updateValue(key, e)}
                              placeholder={"Enter here"}
                            />
                          </Col>
                        ) : ["string", "number", null].includes(
                            typeof value
                          ) ? (
                          <Col
                            md={6}
                            sm={12}
                            className={classes.input__wrapper}
                          >
                            <Input
                              type={typeArray.includes(key) ? "number" : "text"}
                              label={renderLabel}
                              value={value}
                              setter={(e) => updateValue(key, e)}
                              placeholder={"Enter here"}
                              labelStyle={{textTransform:"none"}}
                              
                            />
                          </Col>
                        ) : (
                          <Col
                            md={6}
                            sm={12}
                            className={classes.input__wrapper}
                          >
                            <FileUploadDropzone
                              acceptTypes={{
                                "image/*": [".png", ".jpeg", ".jpg"],
                              }}
                              label={key}
                              setFiles={(e) => {
                                updateValue(key, e);
                              }}
                              files={formFields?.images}
                              allowDrag={true}
                              multiple={true}
                              setDeletedFiles={(e) => setDeleteImages(e)}
                              deletedFiles={deleteImages}
                            />
                          </Col>
                        )}
                      </>
                    );
                  })}

                  <Col md={6} sm={12} className={classes.input__wrapper}>
                    <AddTags
                      value={usageArea}
                      setter={setUsageArea}
                      label={"Usage Area"}
                      placeholder={`Enter Usage Area`}
                    />
                  </Col>

                  <Col md={12} className={"jCenter"}>
                    <Button
                      onClick={handleSubmit}
                      className={classes.saveBtn}
                      disabled={isLoading == "updating"}
                      label={
                        isLoading == "updating"
                          ? slug
                            ? "Updating..."
                            : "Adding..."
                          : slug
                          ? "Update"
                          : "Add"
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </SidebarSkeleton>
  );
}

export default AddEditProduct;
