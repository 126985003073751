import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import Drawer from "react-modern-drawer";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import SideBar from "../SideBar";
import classes from "./SideBarSkeleton.module.css";

const SideBarSkeleton = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    isMobileViewHook(setIsMobile, 1025);
  }, [window.innerWidth]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <style>{`
        .drawerContainer{
          width:320px !important;
        }
        @media (max-width:768px){
          .drawerContainer{
            width:290px !important;
          }
        }
    `}</style>
      <Container fluid className="g-0">
        <Row className="g-0">
          <div
            style={
              {
                // display: paramsFound && "none",
              }
            }
            className={[!isMobile && classes.sidebarDiv].join(" ")}
          >
            {!isMobile ? (
              <SideBar />
            ) : (
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="left"
                className="drawerContainer"
              >
                <SideBar />
              </Drawer>
            )}
          </div>
          <div
            className={[
              !isMobile && classes.desktopContentDiv,
              classes.contentDiv,
            ].join(" ")}
          >
            {isMobile && (
              <div className={classes.mobileHeader}>
                <Container className="dashboardContainer">
                <GiHamburgerMenu
                  className={[classes.GiHamburgerMenu]}
                  onClick={() => {
                    toggleDrawer();
                  }}
                />
                </Container>
              </div>
            )}
            {children}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SideBarSkeleton;
