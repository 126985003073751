import React, { useEffect, useState } from "react";
import classes from "./CreateSubAdmin.module.css";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import { Row, Col, Container } from "react-bootstrap";
import SidebarSkeleton from "../../Component/SideBarSkeleton";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { apiHeader, BaseURL } from "../../config/apiUrl";
import { useNavigate, useParams } from "react-router-dom";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import validator from "validator";
import { DropDown } from "../../Component/DropDown/DropDown";
import { subAdminPermissionsOptions } from "../../constant/commonData";
import { Loader } from "../../Component/Loader";

function CreateSubAdmin() {
  const navigate = useNavigate();
  const slug = useParams()?.slug;
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [formFields, setFormFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    permissions: [],
  });
  const [isLoading, setLoading] = useState("");

  const getSingleSubAdmin = async () => {
    setLoading("loading");
    const response = await Get(BaseURL(`users/sub-admin/${slug}`), accessToken);
    setLoading("");

    if (response) {
      const resData = response?.data?.data;
      setFormFields({
        firstName: resData?.firstName,
        lastName: resData?.lastName,
        email: resData?.email,
        contact: resData?.contact,
        permissions: subAdminPermissionsOptions?.filter((item) =>
          resData?.permissions?.includes(item?.value)
        ),
      });
    }
  };

  useEffect(() => {
    if (slug) {
      getSingleSubAdmin();
    }
  }, []);

  const handleSubmit = async () => {
    let params = {
      firstName: formFields?.firstName,
      lastName: formFields?.lastName,
      ...(!slug ? { email: formFields?.email } : {}),
      contact: formFields?.contact,
    };
    for (const key in params) {
      if (!params[key]) {
        toast.error("Please fill all the fields");
        return;
      }
    }
    if (!slug && !validator.isEmail(formFields?.email)) {
      toast.error("Please enter valid email address");
      return;
    }
    if (!validator.isMobilePhone(`+${params?.contact}`)) {
      toast.error("Please enter valid phone number");
      return;
    }
    if (formFields?.permissions?.length == 0) {
      toast.error("Please select at least one permission");
      return;
    }
    params = {
      ...params,
      permissions: formFields?.permissions?.map((item) => item?.value),
    };
    setLoading("updating");
    const apiFunc = slug ? Patch : Post;
    const url = slug
      ? `users/sub-admin/update/${slug}`
      : "users/sub-admin/create";
    const response = await apiFunc(
      BaseURL(url),
      params,
      apiHeader(accessToken)
    );
    setLoading("");
    if (response !== undefined) {
      toast.success(`Sub Admin  ${slug ? "updated" : "created"} successfully`);
      navigate("/sub-admins");
    }
  };

  const updateValue = (key, value) => {
    setFormFields({ ...formFields, [key]: value });
  };

  return (
    <SidebarSkeleton>
      <Container className={classes.main}>
        {isLoading == "loading" ? (
          <Loader height={"100vh"} />
        ) : (
          <Row>
            <Col md={12}>
              <div className={classes.pageHeader}>
                <h2>{slug ? "Update Sub Admin" : "Add Sub Admin"}</h2>
              </div>
            </Col>
            <Col md={12}>
              <div className={classes.boxShadow__wrapper}>
                <Row className={classes.formRow}>
                  <Col md={6} sm={12} className={classes.input__wrapper}>
                    <Input
                      label="First Name"
                      value={formFields.firstName}
                      setter={(e) => updateValue("firstName", e)}
                      placeholder={"Enter here"}
                    />
                  </Col>
                  <Col md={6} sm={12} className={classes.input__wrapper}>
                    <Input
                      label="Last Name"
                      value={formFields?.lastName}
                      setter={(e) => updateValue("lastName", e)}
                      placeholder={"Enter here"}
                    />
                  </Col>
                  <Col md={6} sm={12} className={classes.input__wrapper}>
                    <Input
                      label="Email"
                      value={formFields?.email}
                      setter={(e) => updateValue("email", e)}
                      placeholder={"Enter here"}
                      disabled={slug}
                    />
                  </Col>
                  <Col md={6} sm={12} className={classes.input__wrapper}>
                    <CustomPhoneInput
                      label="Phone Number"
                      value={formFields?.contact}
                      setter={(e) => updateValue("contact", e)}
                      placeholder={"Enter here"}
                    />
                  </Col>
                  <Col md={12} sm={12} className={classes.input__wrapper}>
                    <DropDown
                      label="Permissions"
                      value={formFields?.permissions}
                      setter={(e) => updateValue("permissions", e)}
                      placeholder={"Enter here"}
                      options={subAdminPermissionsOptions}
                      labelClassName={classes.label}
                      isMulti={true}
                      customStyle={{
                        backgroundColor:'#fff',
                        border:'1px solid var(--7070-clr)',
                      }}
                    />
                  </Col>

                  <Col md={12} className={"jCenter"}>
                    <Button
                      onClick={handleSubmit}
                      className={classes.saveBtn}
                      disabled={isLoading == "updating"}
                      label={
                        isLoading == "updating"
                          ? slug
                            ? "Updating..."
                            : "Adding..."
                          : slug
                          ? "Update"
                          : "Add"
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </SidebarSkeleton>
  );
}

export default CreateSubAdmin;
