import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import SubHeader from "../../Component/SubHeader";
import { cmsPagesTableData } from "../../constant/commonData";
import classes from "./CMSPage.module.css";

const CMSPage = () => {
  const navigate = useNavigate();
  const data = cmsPagesTableData;

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 275px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12}>
                <SubHeader title={"CMS"} />
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div class="table100 ver1 m-b-110">
                  <div class="table100-head">
                    <table>
                      <thead>
                        <tr class="row100 head">
                          <th
                            class="cell100 column1"
                            style={{ width: "20%", textAlign: "start" }}
                          >
                            S/No
                          </th>
                          <th
                            class="cell100 column2"
                            style={{ width: "50%", textAlign: "start" }}
                          >
                            Page Name
                          </th>

                          <th
                            class="cell100 column5"
                            style={{ width: "30%", textAlign: "center" }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {
                    <div className={`table100-body js-pscroll ps ps--active-y`}>
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr
                                class="row100 body"
                               
                              >
                                <td
                                  class="cell100 column1"
                                  style={{ width: "20%", textAlign: "left" }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  class="cell100 column2"
                                  style={{
                                    width: "50%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.name}
                                  </div>
                                </td>

                                <td
                                  class="cell100 column5"
                                  style={{ width: "30%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={"Edit"}
                                      onClick={() => {
                                        navigate(`${item?.path}`);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Pages Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
      </div>
    </>
  );
};
export default CMSPage;
