import React, { useEffect, useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import { TextArea } from "../../Component/TextArea";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import classes from "./AddTestimonialModal.module.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import UploadImageBox from "../../Component/UploadImageBox";

const AddTestimonialModal = ({ show, setShow, loading, onClick, data }) => {
  const [photo, setPhoto] = useState(null);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (data) {
      setTitle(data?.title);
      setName(data?.name);
      setPhoto(data?.photo);
      setDescription(data?.description);
    }
  }, [data]);

  return (
    <>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        header={data ? "Edit Testimonial" : "Add Testimonial"}
        borderRadius={20}
      >
        <Row className={classes.row}>
          <Col md={12}>
            <UploadImageBox label={"Photo (150 x 165)"} setter={setPhoto} state={photo} />
          </Col>
          <Col md={12}>
            <Input
              label={"Name"}
              setter={setName}
              value={name}
              placeholder={"Enter Name"}
            />
          </Col>
          <Col md={12}>
            <Input
              label={"Title"}
              setter={setTitle}
              value={title}
              placeholder={"Enter Title"}
            />
          </Col>
          <Col md={12}>
            <TextArea
              label={"Description"}
              setter={setDescription}
              value={description}
              placeholder={"Enter Description"}
            />
          </Col>
          <Col md={12}>
            <div className={classes.btnsDiv}>
              <Button
                label={loading ? "Wait..." : data ? "Update" : "Submit"}
                onClick={() => {
                  const params = {
                    photo,
                    name,
                    title,
                    description,
                  };
                  for (let i in params) {
                    if (!params[i]) {
                      return toast.error("Please fill all the fields");
                    }
                  }
                  onClick(params);
                }}
                disabled={loading}
              />
            </div>
          </Col>
        </Row>
      </ModalSkeleton>
    </>
  );
};

export default AddTestimonialModal;
