import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdCancel, MdOutlineOndemandVideo } from "react-icons/md";
import { RiAddFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import { Input } from "../../Component/Input/Input";
import { Loader } from "../../Component/Loader";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { TextArea } from "../../Component/TextArea";
import UploadImageBox from "../../Component/UploadImageBox";
import {
  BaseURL,
  CreateFormData,
  apiHeader,
  transformArrayOfObjIntoObj,
} from "../../config/apiUrl";
import classes from "./FooterCMS.module.css";
import validator from "validator";

const fields = [
  {
    label: "Heading Title 1",
    key: "headingTitle1",
    type: "text",
    tagType: "input",
    col: { md: 12 },
    value: "",
  },

  {
    label: "Heading Value 1",
    key: "subTitle1",
    type: "text",
    tagType: "input",
    col: { md: 12 },
    value: null,
  },
  {
    label: "Heading Title 2",
    key: "headingTitle2",
    type: "text",
    tagType: "input",
    col: { md: 12 },
    value: "",
  },

  {
    label: "Heading Value 2",
    key: "subTitle2",
    type: "text",
    tagType: "input",
    col: { md: 12 },
    value: null,
  },
  {
    label: "Social Link",
    key: "socialLinks",
    type: "text",
    tagType: "social-links",
    col: { md: 12 },
    value: [{ url: "" }],
  },
];
function FooterCMS() {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [cmsData, setCmsData] = useState({
    ...transformArrayOfObjIntoObj(fields, "key", "value"),
  });
  console.log(
    ' transformArrayOfObjIntoObj(fields, "key", "value")',
    transformArrayOfObjIntoObj(fields, "key", "value")
  );

  const updateField = (key, val) => {
    setCmsData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const getData = async () => {
    const url = BaseURL("cms/page/footer");
    setIsLoading(true);
    const response = await Get(url, apiHeader(accessToken));
    setIsLoading(false);
    if (response) {
      setCmsData((prev) => ({ ...prev, ...response?.data?.data }));
    }
  };

  const updateData = async () => {
    const url = BaseURL("cms/page/update");
    for (let key in cmsData) {
      if (Array.isArray(cmsData[key])) {
        for (let a = 0; a < cmsData[key]?.length; a++) {
          if (!cmsData[key][a]?.url) {
            return toast.error("Please fill all the fields");
          }
        }
      } else if (!cmsData[key]) {
        return toast.error("Please fill all the fields");
      }
    }
    if (cmsData?.socialLinks?.length == 0) {
      return toast.error("Please add at least one social link");
    }

    for (let key in cmsData?.socialLinks) {
      if (!validator.isURL(cmsData?.socialLinks[key]?.url)) {
        return toast.error("Please enter a valid social link");
      }
    }

    setIsUpdating(true);
    const formData = await CreateFormData(cmsData);
    const response = await Patch(url, formData, apiHeader(accessToken));
    setIsUpdating(false);
    if (response) {
      toast.success("Footer Page updated successfully");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("cmsData", cmsData);

  return (
    <SideBarSkeleton>
      {isLoading ? (
        <Loader />
      ) : (
        <Container className={classes?.page}>
          <Row className={classes?.rowGap}>
            <Col md={12}>
              <h2>Footer CMS</h2>
            </Col>
            {fields?.map((e, i) => (
              <Col {...e?.col}>
                {e?.tagType == "social-links" ? (
                  <>
                    <div className={classes?.addMoreBox}>
                      <Button
                        onClick={() => {
                          if (cmsData[e?.key]?.length >= 4)
                            return toast.error("You can add maximum 4 links");
                          const newData = [...cmsData[e?.key]];
                          newData.push({ url: "" });

                          setCmsData((prev) => ({
                            ...prev,
                            [e?.key]: newData,
                          }));
                        }}
                        variant="outlined-secondary"
                        className={classes?.addMoreBtn}
                      >
                        Add More
                      </Button>
                    </div>
                    {cmsData[e?.key]?.map((item, i) => (
                      <div className={classes?.inputWithBtn}>
                        <Input
                          setter={(a) => {
                            const newData = [...cmsData[e?.key]];
                            newData[i].url = a;
                            setCmsData((prev) => ({
                              ...prev,
                              [e?.key]: newData,
                            }));
                          }}
                          value={cmsData[e?.key][i]?.url}
                          placeholder={e?.placeholder}
                          label={e?.label + " " + (i + 1)}
                          className={classes?.input}
                        />
                        <Button
                          onClick={() => {
                            const newData = [...cmsData[e?.key]];
                            newData.splice(i, 1);

                            setCmsData((prev) => ({
                              ...prev,
                              [e?.key]: newData,
                            }));
                          }}
                        >
                          <MdCancel color="var(--white-color)" size={20} />
                        </Button>
                      </div>
                    ))}
                  </>
                ) : e?.tagType == "phone-input" ? (
                  <CustomPhoneInput
                    setter={(a) => updateField(e?.key, a)}
                    value={cmsData[e?.key]}
                    placeholder={e?.placeholder}
                    label={e?.label}
                  />
                ) : e?.tagType == "input" ? (
                  <Input
                    setter={(a) => updateField(e?.key, a)}
                    value={cmsData[e?.key]}
                    placeholder={e?.placeholder}
                    label={e?.label}
                    type={e?.type}
                  />
                ) : e?.tagType == "textarea" ? (
                  <TextArea
                    setter={(a) => updateField(e?.key, a)}
                    value={cmsData[e?.key]}
                    placeholder={e?.placeholder}
                    label={e?.label}
                    rows={8}
                  />
                ) : e?.tagType == "image" ? (
                  <>
                    {Array.isArray(e?.value) ? (
                      <Row className="gy-2">
                        <Col md={12}>
                          <label className={classes?.label}>{e?.label}</label>
                        </Col>
                        {cmsData[e?.key]?.map((item, i) => (
                          <Col md={3} key={"image" + i}>
                            <UploadImageBox
                              setter={(a) => {
                                const newData = [...cmsData[e?.key]];
                                newData.splice(i, 1, a);
                                updateField(e?.key, newData);
                              }}
                              label={undefined}
                              state={item}
                              placeholder={e?.placeholder}
                              allowdTypes={e?.type}
                              FallbackIcon={
                                Array.isArray(e?.type) &&
                                e?.type
                                  ?.map((e) => e?.split("/")[0])
                                  ?.includes("video") &&
                                MdOutlineOndemandVideo
                              }
                            />
                          </Col>
                        ))}
                        {cmsData[e?.key]?.length < e?.maxLimit && (
                          <Col md={3}>
                            <div
                              className={classes?.addMoreBox}
                              onClick={() => {
                                const newData = [...cmsData[e?.key]];
                                newData.push(null);
                                updateField(e?.key, newData);
                              }}
                            >
                              <p>Add More</p>
                              <RiAddFill size={30} />
                            </div>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      <UploadImageBox
                        setter={(a) => updateField(e?.key, a)}
                        state={cmsData[e?.key]}
                        placeholder={e?.placeholder}
                        label={e?.label}
                        allowdTypes={e?.type}
                        FallbackIcon={
                          Array.isArray(e?.type) &&
                          e?.type
                            ?.map((e) => e?.split("/")[0])
                            ?.includes("video") &&
                          MdOutlineOndemandVideo
                        }
                      />
                    )}
                  </>
                ) : (
                  <UploadImageBox
                    setter={(a) => updateField(e?.key, a)}
                    state={cmsData[e?.key]}
                    placeholder={e?.placeholder}
                    label={e?.label}
                    allowdTypes={e?.type}
                    FallbackIcon={
                      Array.isArray(e?.type) &&
                      e?.type
                        ?.map((e) => e?.split("/")[0])
                        ?.includes("video") &&
                      MdOutlineOndemandVideo
                    }
                  />
                )}
              </Col>
            ))}
            <Col md={12}>
              <Button onClick={updateData} disabled={isUpdating}>
                {isUpdating ? "Updating..." : "Update"}
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </SideBarSkeleton>
  );
}

export default FooterCMS;
