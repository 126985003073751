import React from "react";
import { Col, Row } from "react-bootstrap";
import { FileDownloadFromUrl } from "../../Helper/FileDownloadFromLink";
import { imageUrl } from "../../config/apiUrl";
import ModalSkeleton from "../ModalSkeleton";
import moment from "moment";
import styles from "./ViewJobApplicantFormModal.module.css";

const ViewJobApplicantFormModal = ({ show, setShow, data }) => {
  const personalInfo = [
    {
      title: "First Name",
      value: data?.firstName,
    },
    {
      title: "Last Name",
      value: data?.lastName,
    },
    {
      title: "Email",
      value: data?.email,
    },
    {
      title: "Gender",
      value: data?.gender,
    },
    {
      title: "Date of Birth",
      value: moment(data?.dob).format('DD-MM-YYYY'),
    },
    {
      title: "Address",
      value: data?.address,
    },
    {
      title: "Nationality",
      value: data?.nationality,
    },
    {
      title: "Mobile Number",
      value: data?.mobileNumber,
    },
    {
      title: "Home Phone",
      value: data?.homePhone,
    },
    {
      title: "Iqama Status",
      value: data?.iqamaStatus,
    },
  ];

  const academicInfo = [
    {
      title: "Degree Level",
      value: data?.academicInformation?.degreeLevel,
    },
    {
      title: "Degree Title",
      value: data?.academicInformation?.degreeTitle,
    },
    {
      title: "Major Subject",
      value: data?.academicInformation?.majorSubject,
    },
    {
      title: "Institution",
      value: data?.academicInformation?.institution,
    },
    {
      title: "Completion Year",
      value: moment(data?.academicInformation?.completionYear).format('YYYY'),
    },
    {
      title: "Years Of Experience",
      value: data?.academicInformation?.yearsOfExperience,
    },
  ];

  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"650px"}
      header={"View Applicant Details"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={12}>
          <h5>Personal Info</h5>
        </Col>
        {personalInfo.map((e, i) => (
          <Col md={6}>
            <label>{e?.title}:</label>
            <p>{e?.value ?? "----"}</p>
          </Col>
        ))}

        <Col md={12}>
          <h5>Academic Info</h5>
        </Col>
        {academicInfo.map((e, i) => (
          <Col md={6}>
            <label>{e?.title}:</label>
            <p>{e?.value ?? "----"}</p>
          </Col>
        ))}

        <Col md={12}>
          <h5>Uploaded CV</h5>
        </Col>
        <Col md={12}>
          <span
            onClick={() => {
              FileDownloadFromUrl(
                imageUrl(data?.documents?.[0]),
                `${data?.firstName + " " + data?.lastName}-CV`
              );
            }}
            style={{
              color: "blue",
            }}
            className="c-p"
          >
            Download
          </span>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default ViewJobApplicantFormModal;
