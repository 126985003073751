import { useState } from "react";
import classes from "./AddTags.module.css";
import { Input } from "../Input/Input";
import { RxCross2 } from "react-icons/rx";
import validator from "validator";
import AddMoreBtn from "../AddMoreBtn";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const AddTags = ({
  value,
  setter,
  label = "",
  placeholder = "Enter Tags",
  forSocialLinks,
}) => {
  const [tag, setTag] = useState("");

  const deleteTag = (item) => {
    // value?.splice(item, 0);
    setter((prevArr) => {
      const newArr = [...prevArr];
      newArr.splice(item, 1);

      return [...newArr];
    });
  };

  /* eslint-disable */

  // addTaskInList
  const addTaskInList = (value) => {
    if (value === "") return;

    if (forSocialLinks && validator.isURL(value)) {
      setter((pre) => [...pre, value]);
      setTag("");
    } else if (!forSocialLinks) {
      setter((pre) => [...pre, value]);
      setTag("");
    }
  };

  return (
    <>
      <div className={classes["tag-wrapper"]}>
        <div
          className={classes["header-title"]}
          data-label={!!label}
        >
          {label && <span>{label}</span>}
          <AddMoreBtn onClick={addTaskInList.bind(null, tag)} />
        </div>
        <div className={`${classes["input-tags-container"]}`}>
          <Input
            onEnterClick={() => {}}
            placeholder={placeholder}
            type={`text`}
            value={tag}
            setter={(e) => {
              setTag(e);
            }}
            customStyle={{
              borderRadius: "8px",
            }}
            onKeyDown={(e) => {
              let value = e?.target?.value;
              if (e.keyCode === 13 && tag === "") {
                return;
              }

              if (e.keyCode === 13) {
                addTaskInList(value);
              }
            }}
          />

          {/* Iota */}
          <Tooltip title="Press enter to add an item." placement="left">
            <IconButton className={classes?.iotaButton}>
              <InfoIcon color="var(--text-color)" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {value?.length > 0 && (
        <div className={`scroller ${classes["tags-list"]}`}>
          {value?.map((item, i) => {
            return (
              <div key={i} className={classes["tag-item"]}>
                <p>{item}</p>
                <div
                  className={classes["action-btn"]}
                  onClick={() => deleteTag(i)}
                >
                  <RxCross2 color={`#fff`} size={15} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AddTags;
