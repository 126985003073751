import React, { useEffect, useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import { TextArea } from "../../Component/TextArea";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import classes from "./AddHomeCarouselModal.module.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import UploadImageBox from "../../Component/UploadImageBox";
import QuillInput from "../../Component/QuillInput";

const AddHomeCarouselModal = ({ show, setShow, loading, onClick, data }) => {
  const [photo, setPhoto] = useState(null);
  const [subTitle, setSubTitle] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (data) {
      setSubTitle(data?.subTitle);
      setTitle(data?.title);
      setPhoto(data?.image);
      setDescription(data?.description);
    }
  }, [data]);

  return (
    <>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        header={data ? "Edit Home Page Hero Section" : "Add Home Page Hero Section"}
        borderRadius={20}
        modalClass={classes.modalClass}
      >
        <Row className={classes.row}>
          <Col md={12}>
            <UploadImageBox label={"Photo (1903 x 477)"} setter={setPhoto} state={photo} />
          </Col>
          <Col md={12}>
            <Input
              label={"Subtitle"}
              setter={setSubTitle}
              value={subTitle}
              placeholder={"Enter Subtitle"}
            />
          </Col>
          <Col md={12}>
            <QuillInput
              label={"Title"}
              setter={setTitle}
              value={title}
              placeholder={"Enter Title"}
              quillClass={classes.quillInput}
            />
          </Col>

          <Col md={12}>
            <TextArea
              label={"Description"}
              setter={setDescription}
              value={description}
              placeholder={"Enter Description"}
            />
          </Col>
          <Col md={12}>
            <div className={classes.btnsDiv}>
              <Button
                label={loading ? "Wait..." : data ? "Update" : "Submit"}
                onClick={() => {
                  const params = {
                    image:photo,
                    title,
                    subTitle,
                    description,
                  };
                  for (let i in params) {
                    if (!params[i]) {
                      return toast.error("Please fill all the fields");
                    }
                  }
                  onClick(params);
                }}
                disabled={loading}
              />
            </div>
          </Col>
        </Row>
      </ModalSkeleton>
    </>
  );
};

export default AddHomeCarouselModal;
