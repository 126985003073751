import axios from "axios";
import { toast } from "react-toastify";

export default async function AwsVideoUploader(
  url,
  file,
  setProgress,
  setIsUploading
) {
  setIsUploading && setIsUploading(true);
  const response = await axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
    onUploadProgress: (data) => {
      setProgress && setProgress(Math.round((100 * data.loaded) / data.total));
    },
  });
  setIsUploading && setIsUploading(false);

  if (response?.status != 200) {
    toast.error("Error uploading file");
  }
  return await response;
}
