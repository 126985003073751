import Parser from 'html-react-parser';
import React from "react";
import { Col, Row } from "react-bootstrap";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import UploadImageBox from "../../Component/UploadImageBox";
import ModalSkeleton from "../ModalSkeleton";
import styles from "./ViewHomeCarouselModal.module.css";

const ViewHomeCarouselModal = ({ show, setShow, data }) => {
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"View Home Page Hero Section"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={12}>
          <UploadImageBox state={data?.image} edit={false}/>
        </Col>
        <Col md={12}>
          <label>Sub Title:</label>
          <p>{data?.subTitle}</p>
        </Col>
        <Col md={12}>
          <label>Title:</label>
          <p className={styles?.title}>{Parser(data?.title)}</p>
        </Col>
       
        <Col md={12}>
          <label>Description:</label>
          <ShowMoreShowLessText text={data?.description} visibility={200} />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default ViewHomeCarouselModal;
