import React, { useState } from "react";
import { Button } from "../../Component/Button/Button";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./SendNewsletterModal.module.css";
import QuillInput from "../../Component/QuillInput";
import { toast } from "react-toastify";
const SendNewsletterModal = ({ show, setShow, isApiCall, onClick }) => {
  const [message, setMessage] = useState("");

  console.log(message);
  return (
    <div>
      <ModalSkeleton
        setShow={setShow}
        show={show}
        width={"700px"}
        header={`Send Email`}
        borderRadius={"20px"}
      >
        <div className={classes.main}>
          <div className={classes.body}>
            <div className={classes.inputDiv}>
              <QuillInput
                label={"Message"}
                placeholder="Enter message here"
                value={message}
                setter={setMessage}
                quillClass={classes.quillInput}
              />
            </div>
          </div>
          <div className={"text-center my-4"}>
            <Button
              disabled={isApiCall}
              label={isApiCall ? "Please Wait..." : "Submit"}
              onClick={() => {
                if (!message || message === "<p><br></p>") {
                  return toast.error("Please enter the message");
                }
                onClick({
                  message,
                });
              }}
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default SendNewsletterModal;
