import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import SubHeader from "../../Component/SubHeader";
import TableSkeleton from "../../Component/TableSkeleton";
import {
  BaseURL,
  apiHeader,
  recordsLimit,
} from "../../config/apiUrl";
import { activeDeactiveWithAllStatusoptions } from "../../constant/commonData";
import classes from "./SubAdmins.module.css";

const SubAdmins = () => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(
    Array(20).fill({ name: "Plastic Poly Pellets", createdAt: "12-12-2023" })
  );
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [btnLoading, setBtnLoading] = useState("");

  const [status, setStatus] = useState(activeDeactiveWithAllStatusoptions[0]);
  const [search, setSearch] = useState("");

  const handleStatus = async (sts, id) => {
    const params = {
      status: sts,
    };
    setBtnLoading(id);
    const response = await Patch(
      BaseURL(`users/sub-admin/active-deactivate/${id}`),
      params,
      apiHeader(accessToken)
    );
    if (response !== undefined) {
      const newData = [...data];
      if (status?.value === "all") {
        newData?.splice(
          newData?.findIndex((item) => item?._id == response?.data?.data?._id),
          1,
          response?.data?.data
        );
      } else {
        newData?.splice(
          newData?.findIndex((item) => item?._id == response?.data?.data?._id),
          1
        );
      }
      setData(newData);
      toast.success(
        `Sub Admin has been ${
          !sts ? "Activated" : "Deactivated"
        } successfully`
      );
    }
    setBtnLoading("");
  };

  async function getData(pg = page, sts = status?.value, searchText = search) {
    const url = BaseURL(
      `users/sub-admin/all?status=${sts}&page=${pg}&limit=${recordsLimit}&search=${searchText}`
    );
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 330px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12}>
                <SubHeader
                  title={"Sub Admins"}
                  search={search}
                  setSearch={(e) => {
                    setPage(1);
                    setSearch(e);
                    getData(1, status?.value, e);
                  }}
                  status={status}
                  setStatus={(e) => {
                    setPage(1);
                    setStatus(e);
                    getData(1, e?.value);
                  }}
                  btnLabel="Add New Sub Admin"
                  options={activeDeactiveWithAllStatusoptions}
                  onAddClick={() => {
                    navigate("/sub-admins/create");
                  }}
                />
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div class="table100 ver1 m-b-110">
                  <div class="table100-head">
                    <table>
                      <thead>
                        <tr class="row100 head">
                          <th
                            class="cell100 column1"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            S/No
                          </th>
                          <th
                            class="cell100 column2"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Name
                          </th>
                          <th
                            class="cell100 column2"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Email
                          </th>
                          <th
                            class="cell100 column2"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            Contact
                          </th>

                          <th
                            class="cell100 column3"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Created At
                          </th>

                          <th
                            class="cell100 column4"
                            style={{ width: "10%", textAlign: "start" }}
                          >
                            Status
                          </th>

                          <th
                            class="cell100 column5"
                            style={{ width: "25%", textAlign: "center" }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className={`table100-body js-pscroll ps ps--active-y`}>
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr
                                class="row100 body"
                                style={
                                  {
                                    // backgroundColor:
                                    // selectedItem?._id == item?._id && "#E5F5FF",
                                  }
                                }
                              >
                                <td
                                  class="cell100 column1"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  {/* {index + 1 + (page - 1) * recordsLimit} */}
                                  {index + 1}
                                </td>
                                <td
                                  class="cell100 column2"
                                  style={{
                                    width: "15%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {[item?.firstName, item?.lastName].join(
                                      " "
                                    )}
                                  </div>
                                </td>
                                <td
                                  class="cell100 column2"
                                  style={{
                                    width: "15%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.email}
                                  </div>
                                </td>
                                <td
                                  class="cell100 column2"
                                  style={{
                                    width: "10%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.contact}
                                  </div>
                                </td>
                                <td
                                  class="cell100 column4"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  <div className={classes.descCol}>
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY hh:mm a"
                                    )}
                                  </div>
                                </td>

                                <td
                                  class="cell100 column4"
                                  style={{ width: "10%", textAlign: "left" }}
                                >
                                  {!item?.isBlockedByAdmin
                                    ? "Active"
                                    : "Deactive"}
                                </td>
                                <td
                                  class="cell100 column5"
                                  style={{ width: "25%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={"Edit"}
                                      onClick={() => {
                                        navigate(`/sub-admins/${item?.slug}`);
                                      }}
                                    />

                                    <Button
                                      className={classes.viewDetailBtn}
                                      variant="primary"
                                      label={
                                        btnLoading === item?._id
                                          ? "Wait..."
                                          : item?.isBlockedByAdmin
                                          ? "Activate"
                                          : "Deactivate"
                                      }
                                      onClick={() => {
                                        handleStatus(
                                          item?.isBlockedByAdmin ? false : true,
                                          item?.slug
                                        );
                                      }}
                                    />

                                  
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Sub Admins Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e, status?.value);
                    }}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
      </div>
    </>
  );
};
export default SubAdmins;
