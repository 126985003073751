import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import { BaseURL, apiHeader } from "../../config/apiUrl";
import { resetIcon } from "../../constant/imagePath";
import classes from "./OtpScreen.module.css";

const OtpScreen = () => {
  const navigate = useNavigate();
  const email = useLocation()?.state?.email;

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const submitHandler = async () => {
    const body = {
      otpCode: otp,
      email,
      fromForgotPassword: true,
    };

    if (otp?.length < 6) {
      return toast.error("Please input the code");
    }

    setLoading(true);
    const response = await Post(
      BaseURL("users/verify-user-otp"),
      body,
      apiHeader()
    );
    setLoading(false);

    if (response !== undefined) {
      toast.success("OTP verified successfully!");
      navigate("/reset-password", { state: { email, otpCode: otp } });
    }
  };

  useEffect(() => {
    if (!email) {
      navigate(-1);
    }
  }, []);
  useEffect(() => {
    isMobileViewHook(setIsMobile, 992);
  }, []);
  return (
    <div className={classes.mainContainer}>
      <Row className="gx-0">
        {!isMobile && (
          <Col xl={5} lg={5} md={12} className={classes.col1}>
            <div className={classes.logo}>
              {/* <img src={whiteLogo} className="mb-4" alt="" /> */}
              <h3 style={{ color: "#fff" }}>Demo</h3>
            </div>

            <div className={classes.desc}>
              <p>Copyright 2022, All Rights Reserved.</p>
            </div>
          </Col>
        )}
        <Col xl={7} lg={7} md={12}>
          <div className={classes.col2}>
            <div className={classes.formWrapper}>
              <Button
                className={classes?.backBtn}
                variant="outlined-secondary"
                onClick={() => navigate("/")}
              >
                Back to Login
              </Button>
              <div className={classes.form}>
                <div className={classes.iconWrapper}>
                  <img src={resetIcon} />
                </div>
                <>
                  <div className={classes.content}>
                    <h5> OTP Code </h5>
                    <p className={"muted"}>Enter your otp code</p>
                  </div>
                  <div className={classes.otpMain}>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      inputStyle={classes.OtpInput_style}
                      numInputs={6}
                      inputType="tel"
                      shouldAutoFocus={true}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  <Button
                    onClick={submitHandler}
                    className={classes.btn}
                    disabled={loading}
                  >
                    {loading ? "Verifing..." : "Verify"}
                  </Button>
                </>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OtpScreen;
