import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { Loader } from "../../Component/Loader";
import QuillInput from "../../Component/QuillInput";
import SidebarSkeleton from "../../Component/SideBarSkeleton";
import {
  BaseURL,
  apiHeader
} from "../../config/apiUrl";
import classes from "./AddEditJob.module.css";

function AddEditJob() {
  const navigate = useNavigate();
  const slug = useParams()?.slug;
  const { accessToken } = useSelector((state) => state?.authReducer);
  const [isLoading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    role: "",
    description: "",
  });

  const handleSubmit = async () => {
    const params = {
      role: formFields?.role,
      description: formFields?.description,
    };
    for (let key in params) {
      if (!params[key]) {
        return toast.error("Please fill all fields");
      }
    }
    const apiType = slug ? Patch : Post;
    setLoading("updating");
    const response = await apiType(
      BaseURL(slug ? `job/${slug}` : `job`),
      params,
      apiHeader(accessToken)
    );
    setLoading("");

    if (response) {
      toast.success(`Job ${slug ? "edited" : "created"} successfully`);
      navigate("/all-jobs");
    }
  };

  async function getData() {
    if (slug) {
      getSingleData(slug);
    }
  }

  const getSingleData = async () => {
    setLoading("loading");
    const response = await Get(BaseURL(`job/detail/${slug}`), accessToken);
    setLoading("");

    if (response) {
      const newData = response?.data?.data;
      setFormFields({
        role: newData?.role,
        description: newData?.description,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const updateValue = (key, value) => {
    setFormFields({ ...formFields, [key]: value });
  };

  return (
    <SidebarSkeleton>
      <Container className={classes.main}>
        {isLoading == "loading" ? (
          <Loader height={"100vh"} />
        ) : (
          <Row>
            <Col md={12}>
              <div className={classes.pageHeader}>
                <h2>{slug ? "Update Job" : "Add Job"}</h2>
              </div>
            </Col>
            <Col md={12}>
              <div className={classes.boxShadow__wrapper}>
                <Row className={classes.formRow}>
                  <Col sm={12} className={classes.input__wrapper}>
                    <Input
                      label={"Title"}
                      value={formFields?.role}
                      setter={(e) => updateValue("role", e)}
                      placeholder={"Enter here"}
                    />
                  </Col>
                  <Col sm={12} className={classes.input__wrapper}>
                    <QuillInput
                      label={"Description"}
                      value={formFields?.description}
                      setter={(e) => updateValue("description", e)}
                      placeholder={"Enter here"}
                      quillClass={classes?.quillInput}
                    />
                  </Col>

                  <Col md={12} className={"jCenter"}>
                    <Button
                      onClick={handleSubmit}
                      className={classes.saveBtn}
                      disabled={isLoading == "updating"}
                      label={
                        isLoading == "updating"
                          ? slug
                            ? "Updating..."
                            : "Adding..."
                          : slug
                          ? "Update"
                          : "Add"
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </SidebarSkeleton>
  );
}

export default AddEditJob;
