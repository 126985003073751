import React from "react";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../ModalSkeleton";
import styles from "./ViewProductLeadModal.module.css";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";

const ViewProductLeadModal = ({ show, setShow, data }) => {
  const info = [
    {
      title: "Customer Name",
      value: data?.name,
    },

    {
      title: "Email",
      value: data?.email,
    },
    {
      title: "Phone",
      value: data?.phone,
    },
    {
      title: "Subject",
      value: data?.subject,
    },
  ];

  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"650px"}
      header={"View Product Lead"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        {info.map((e, i) => (
          <Col md={6}>
            <label>{e?.title}:</label>
            <p className="t-t-c">{e?.value ?? "----"}</p>
          </Col>
        ))}
        <Col md={12}>
          <label>Message:</label>
          <ShowMoreShowLessText text={data?.message} visibility={200} />
        </Col>

        <Col md={12}>
          <h6>Products</h6>
        </Col>
        {data?.products?.map((e, i) => (
          <>
            <Col md={6}>
              <label>Name:</label>
              <p className="t-t-c">{e?.product?.name}</p>
            </Col>
            <Col md={6}>
              <label>Quantity:</label>
              <p className="t-t-c">{e?.quantity ?? 0}</p>
            </Col>
          </>
        ))}
      </Row>
    </ModalSkeleton>
  );
};

export default ViewProductLeadModal;
