import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { RiAddFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import { Input } from "../../Component/Input/Input";
import { Loader } from "../../Component/Loader";
import QuillInput from "../../Component/QuillInput";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { TextArea } from "../../Component/TextArea";
import UploadImageBox from "../../Component/UploadImageBox";
import AwsVideoUploader from "../../Helper/AwsVideoUploader";
import {
  BaseURL,
  CreateFormData,
  apiHeader,
  transformArrayOfObjIntoObj,
} from "../../config/apiUrl";
import classes from "./SustainabilityCMS.module.css";

const fields = [
  // Hero Section
  {
    label: "Hero Section Video (1903 x 362)",
    key: "video",
    type: ["video/mp4"],
    tagType: "video",
    col: { md: 12 },
    value: null,
  },
  // Section 1
  {
    label: "Section 1 Title",
    key: "section1Title",
    type: "text",
    tagType: "quill",
    col: { md: 6 },
    value: "",
  },
  {
    label: "Section 1 Description",
    key: "section1Description",
    type: "text",
    tagType: "quill",
    col: { md: 6 },
    value: "",
  },
  {
    label: "Section 1 Image (1410 x 597)",
    key: "section1Image",
    type: ["image/jpg", "image/jpeg", "image/png"],
    tagType: "image",
    col: { md: 12 },
    value: null,
  },

  // Section 2

  {
    label: "Section 2 Description",
    key: "section2Description",
    type: "text",
    tagType: "quill",
    col: { md: 6 },
    value: "",
  },
  {
    label: "Section 2 Image (1410 x 597)",
    key: "section2Image",
    type: ["image/jpg", "image/jpeg", "image/png"],
    tagType: "image",
    col: { md: 12 },
    value: null,
  },

  // Section 3
  {
    label: "Section 3 Title",
    key: "section3Title",
    type: "text",
    tagType: "quill",
    col: { md: 6 },
    value: "",
  },
  {
    label: "Section 3 Description",
    key: "section3Description",
    type: "text",
    tagType: "quill",
    col: { md: 6 },
    value: "",
  },
  {
    label: "Section 3 Image (1410 x 597)",
    key: "section3Image",
    type: ["image/jpg", "image/jpeg", "image/png"],
    tagType: "image",
    col: { md: 12 },
    value: null,
  },
  // Section 4

  {
    label: "Section 4 Description",
    key: "section4Description",
    type: "text",
    tagType: "quill",
    col: { md: 6 },
    value: "",
  },
  {
    label: "Section 4 Image (1410 x 597)",
    key: "section4Image",
    type: ["image/jpg", "image/jpeg", "image/png"],
    tagType: "image",
    col: { md: 12 },
    value: null,
  },

  // Section 5
  {
    label: "Section 5 Title",
    key: "section5Title",
    type: "text",
    tagType: "quill",
    col: { md: 6 },
    value: "",
  },
  {
    label: "Section 5 Description",
    key: "section5Description",
    type: "text",
    tagType: "quill",
    col: { md: 6 },
    value: "",
  },
  {
    label: "Section 5 Image (1410 x 597)",
    key: "section5Image",
    type: ["image/jpg", "image/jpeg", "image/png"],
    tagType: "image",
    col: { md: 12 },
    value: null,
  },
];
function SustainabilityCMS() {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [cmsData, setCmsData] = useState(
    transformArrayOfObjIntoObj(fields, "key", "value")
  );

  const updateField = (key, val) => {
    setCmsData((prev) => ({
      ...prev,
      [key]: val,
      ...(key == "video" ? { isVideo: true } : {}),
    }));
  };

  const getData = async () => {
    const url = BaseURL("cms/page/sustainability");
    setIsLoading(true);
    const response = await Get(url, apiHeader(accessToken));
    setIsLoading(false);
    if (response) {
      setCmsData((prev) => ({ ...prev, ...response?.data?.data }));
    }
  };

  const uploadVideo = async (url, file) => {
    const res = await AwsVideoUploader(url, file, null, setIsUpdating);
    return res;
  };
  const updateData = async () => {
    const url = BaseURL("cms/page/update");
    for (let key in cmsData) {
      if (!cmsData[key]) {
        return toast.error("Please fill all the fields");
      }
    }
    setIsUpdating(true);
    const { video, ...body } = cmsData;
    const formData = await CreateFormData({
      ...body,
      ...(typeof video == "string" ? { video } : {}),
    });
    const response = await Patch(url, formData, apiHeader(accessToken));
    setIsUpdating(false);
    if (response) {
      if (cmsData?.isVideo) {
        await uploadVideo(response?.data?.url, cmsData?.video);
      }
      toast.success("Sustainability Page updated successfully");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <SideBarSkeleton>
      {isLoading ? (
        <Loader />
      ) : (
        <Container className={classes?.page}>
          <Row className={classes?.rowGap}>
            <Col md={12}>
              <h2>Sustainability CMS</h2>
            </Col>
            {fields?.map((e, i) => (
              <Col {...e?.col}>
                {e?.tagType == "quill" ? (
                  <QuillInput
                    setter={(a) => updateField(e?.key, a)}
                    value={cmsData[e?.key]}
                    placeholder={e?.placeholder}
                    label={e?.label}
                    quillClass={classes?.quillClass}
                  />
                ) : e?.tagType == "phone-input" ? (
                  <CustomPhoneInput
                    setter={(a) => updateField(e?.key, a)}
                    value={cmsData[e?.key]}
                    placeholder={e?.placeholder}
                    label={e?.label}
                  />
                ) : e?.tagType == "input" ? (
                  <Input
                    setter={(a) => updateField(e?.key, a)}
                    value={cmsData[e?.key]}
                    placeholder={e?.placeholder}
                    label={e?.label}
                    type={e?.type}
                  />
                ) : e?.tagType == "textarea" ? (
                  <TextArea
                    setter={(a) => updateField(e?.key, a)}
                    value={cmsData[e?.key]}
                    placeholder={e?.placeholder}
                    label={e?.label}
                    rows={8}
                  />
                ) : e?.tagType == "image" ? (
                  <>
                    {Array.isArray(e?.value) ? (
                      <Row className="gy-2">
                        <Col md={12}>
                          <label className={classes?.label}>{e?.label}</label>
                        </Col>
                        {cmsData[e?.key]?.map((item, i) => (
                          <Col md={3} key={"image" + i}>
                            <UploadImageBox
                              setter={(a) => {
                                const newData = [...cmsData[e?.key]];
                                newData.splice(i, 1, a);
                                updateField(e?.key, newData);
                              }}
                              label={undefined}
                              state={item}
                              placeholder={e?.placeholder}
                              allowdTypes={e?.type}
                              FallbackIcon={
                                Array.isArray(e?.type) &&
                                e?.type
                                  ?.map((e) => e?.split("/")[0])
                                  ?.includes("video") &&
                                MdOutlineOndemandVideo
                              }
                            />
                          </Col>
                        ))}
                        {cmsData[e?.key]?.length < e?.maxLimit && (
                          <Col md={3}>
                            <div
                              className={classes?.addMoreBox}
                              onClick={() => {
                                const newData = [...cmsData[e?.key]];
                                newData.push(null);
                                updateField(e?.key, newData);
                              }}
                            >
                              <p>Add More</p>
                              <RiAddFill size={30} />
                            </div>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      <UploadImageBox
                        setter={(a) => updateField(e?.key, a)}
                        state={cmsData[e?.key]}
                        placeholder={e?.placeholder}
                        label={e?.label}
                        allowdTypes={e?.type}
                        FallbackIcon={
                          Array.isArray(e?.type) &&
                          e?.type
                            ?.map((e) => e?.split("/")[0])
                            ?.includes("video") &&
                          MdOutlineOndemandVideo
                        }
                      />
                    )}
                  </>
                ) : (
                  <UploadImageBox
                    setter={(a) => updateField(e?.key, a)}
                    state={cmsData[e?.key]}
                    placeholder={e?.placeholder}
                    label={e?.label}
                    allowdTypes={e?.type}
                    FallbackIcon={
                      Array.isArray(e?.type) &&
                      e?.type
                        ?.map((e) => e?.split("/")[0])
                        ?.includes("video") &&
                      MdOutlineOndemandVideo
                    }
                  />
                )}
              </Col>
            ))}
            <Col md={12}>
              <Button onClick={updateData} disabled={isUpdating}>
                {isUpdating ? "Updating..." : "Update"}
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </SideBarSkeleton>
  );
}

export default SustainabilityCMS;
