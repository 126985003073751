import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import { BaseURL, apiHeader } from "../../config/apiUrl";
import { resetIcon } from "../../constant/imagePath";
import classes from "./UpdatePassword.module.css";

const ResetPassword = () => {
  const navigate = useNavigate();
  const email = useLocation()?.state?.email;
  const otpCode = useLocation()?.state?.otpCode;

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const options = [
    { value: "English", label: "English" },
    { value: "French", label: "French" },
    { value: "German", label: "German" },
  ];
  const submitHandler = async () => {
    const body = {
      email,
      password: newPassword,
      confirmPassword: confirmNewPassword,
      fromWeb: true,
      otpCode
    };
    for (let key in body) {
      if (!body[key]) {
        return toast.error("Please fill all the fields");
      }
    }
    if (body?.password?.length < 8) {
      return toast.error("New Password must be 8 characters or more");
    }
    if (body?.confirmPassword?.length < 8) {
      return toast.error("Confirm Password must be 8 characters or more");
    }
    if (body?.confirmPassword !== body.password) {
      return toast.error("Passwords must match");
    }
    setLoading(true);
    const response = await Post(
      BaseURL("users/resetPassword"),
      body,
      apiHeader()
    );
    setLoading(false);

    if (response !== undefined) {
      toast.success("Your password has been reset successfully!");
      navigate("/");
    }
  };

  useEffect(() => {
    if (!email) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    isMobileViewHook(setIsMobile, 992);
  }, []);
  return (
    <div className={classes.mainContainer}>
      <Row className="gx-0">
        {!isMobile && (
          <Col xl={5} lg={5} md={12} className={classes.col1}>
            <div className={classes.logo}>
              {/* <img src={whiteLogo} className="mb-4" alt="" /> */}
              <h3 style={{ color: "#fff" }}>Demo</h3>
            </div>

            <div className={classes.desc}>
              <p>Copyright 2022, All Rights Reserved.</p>
            </div>
          </Col>
        )}
        <Col xl={7} lg={7} md={12}>
          <div className={classes.col2}>
            <div className={classes.formWrapper}>
              <Button
                className={classes?.backBtn}
                variant="outlined-secondary"
                onClick={() => navigate("/")}
              >
                Back to Login
              </Button>
              <div className={classes.form}>
                <div className={classes.iconWrapper}>
                  <img src={resetIcon} />
                </div>
                <>
                  <div className={classes.content}>
                    <h5> Reset Password </h5>
                    <p className={"muted"}>Enter your new password</p>
                  </div>
                  <div className={classes.inputWrapper}>
                    <Input
                      value={newPassword}
                      setter={setNewPassword}
                      placeholder={"New password"}
                      type={"password"}
                    />
                    <Input
                      value={confirmNewPassword}
                      setter={setConfirmNewPassword}
                      placeholder={"Confirm Password"}
                      type={"password"}
                    />
                    <Button
                      onClick={submitHandler}
                      className={classes.btn}
                      disabled={loading}
                    >
                      {loading ? "Resetting..." : "Reset Password"}
                    </Button>
                  </div>
                </>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
