import React from "react";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../ModalSkeleton";
import styles from "./ViewTestimonialModal.module.css";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import UploadImageBox from "../../Component/UploadImageBox";

const ViewTestimonialModal = ({ show, setShow, data }) => {
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"View Testimonial"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles.row}>
        <Col md={12}>
          <UploadImageBox state={data?.photo} edit={false}/>
        </Col>
        <Col md={12}>
          <label>Name:</label>
          <p>{data?.name}</p>
        </Col>
        <Col md={12}>
          <label>Title:</label>
          <p>{data?.title}</p>
        </Col>
        <Col md={12}>
          <label>Description:</label>
          <ShowMoreShowLessText text={data?.description} visibility={200} />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default ViewTestimonialModal;
