import React from "react";
import { BiSearch } from "react-icons/bi";
import { Input } from "../Input/Input";
import classes from "./SearchInput.module.css";

function SearchInput({
  value,
  setter,
  placeholder = "Search",
  customStyle = {
    height: "43px",
    borderRadius: "8px",
    boxShadow: "0px 3px 6px #00000029",
    width: "280px",
    padding: "0px",
  },
  inputStyle = {
    padding: "8px 14px 8px 40px",
    fontSize: "14px",
  },
}) {
  return (
    <Input
      className={classes.searchInput}
      setter={setter}
      value={value}
      customStyle={customStyle}
      inputStyle={inputStyle}
      placeholder={placeholder}
      leftIcon={<BiSearch size={22} color={"#000"} />}
    />
  );
}

export default SearchInput;
