export const allTaskStatusoptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Archive",
    value: "completed",
    status: "Archive",
  },
  {
    label: "Modify-Request",
    value: "modify-request",
    status: "Modify-Request",
  },
  {
    label: "In Progress",
    value: "accepted",
    status: "In Progress",
  },
  {
    label: "Cancelled Tasks",
    value: "cancelled",
    status: "Cancelled Task",
  },
  {
    label: "Awaiting Acceptance",
    value: "pending",
    status: "Awaiting Acceptance",
  },
];

export const activeDeactiveStatusoptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: true,
  },
  {
    label: "Deactive",
    value: false,
  },
];

export const activeDeactiveWithAllStatusoptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: true,
  },
  {
    label: "Deactive",
    value: false,
  },
];

export const cmsPagesTableData = [
  { name: "Home", path: "/cms/home" },
  { name: "Home Page Hero Section Carousel", path: "/cms/home-hero-carousel" },
  { name: "Home Page Second Section Carousel", path: "/cms/home-second-section-carousel" },
  { name: "About Us", path: "/cms/about-us" },
  { name: "Products", path: "/cms/products" },
  { name: "Sustainability", path: "/cms/sustainability" },
  { name: "Sustainability CRUD", path: "/cms/sustainability-crud" },
  { name: "Careers", path: "/cms/careers" },
  { name: "Contact Us", path: "/cms/contact-us" },
  { name: "FAQs", path: "/cms/faqs" },
  { name: "Testimonials", path: "/cms/testimonials" },
  { name: "Latest News", path: "/cms/latest-news" },
  { name: "Terms & Conditions", path: "/cms/terms-and-conditions" },
  { name: "Privacy Policy", path: "/cms/privacy-policy" },
  { name: "Footer", path: "/cms/footer" },
];

export const subAdminPermissionsOptions = [
  { label: "Products", value: "products", path: ["/all-products","/product-detail/:slug","/add-product","/edit-product/:slug"] },
  { label: "Careers", value: "careers", path: ["/all-jobs", "/applicants","/all-jobs/create","/all-jobs/:slug"] },
  {
    label: "Leads",
    value: "leads",
    path: ["/products-leads", "/contact-form-leads"],
  },
  {
    label: "Newsletters",
    value: "newsletters",
    path: ["/newsletters"],
  },
  {
    label: "CMS",
    value: "cms",
    path: [
      "/cms",
      "/cms/home",
      "/cms/home-hero-carousel",
      "/cms/about-us",
      "/cms/products",
      "/cms/sustainability",
      "/cms/sustainability-crud",
      "/cms/careers",
      "/cms/contact-us",
      "/cms/faqs",
      "/cms/testimonials",
      "/cms/latest-news",
      "/cms/terms-and-conditions",
      "/cms/privacy-policy",
      "/cms/footer",
    ],
  },
];
