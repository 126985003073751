import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Button } from "../../Component/Button/Button";
import ElasticCarousel from "../../Component/ElasticCarousel";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { BaseURL, imageUrl } from "../../config/apiUrl";
import classes from "./ProductDetail.module.css";
import { useEffect } from "react";
import { Get } from "../../Axios/AxiosFunctions";
import { Loader } from "../../Component/Loader";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
const ProductDetail = () => {
  const dispatch = useDispatch();
  const slug = useParams()?.slug;
  const { accessToken, isLogin, user } = useSelector(
    (state) => state?.authReducer
  );
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getProductData = async () => {
    setLoading(true);
    const response = await Get(BaseURL(`product/detail/${slug}`), accessToken);
    setLoading(false);
    if (response) {
      setData(response?.data?.data);
    }
  };
  useEffect(() => {
    getProductData();
  }, []);

  // const cartProducts = useSelector((state) => state.cartReducer?.products);
  const productImages = [
    // { mainImage: ProductDetailImg1, fullImage: ProductDetailImg2 },
    // { mainImage: ProductDetailImg1, fullImage: ProductDetailImg2 },
    // { mainImage: ProductDetailImg1, fullImage: ProductDetailImg2 },
    // { mainImage: ProductDetailImg1, fullImage: ProductDetailImg2 },
    // { mainImage: ProductDetailImg360, fullImage: ProductDetailImg2 },
  ];
  const [selectedProduct, setSelectedProduct] = useState(productImages[0]);
  const breakpoints = [
    { width: 1, pagination: false, itemsToShow: 4, itemPadding: [10, 10] },
    { width: 540, pagination: false, itemsToShow: 5, itemPadding: [10, 10] },
  ];
  // function addToCard(e, quantity = 1) {
  //   const findIndex = cartProducts.findIndex(
  //     (item) => item.product?._id === e?.product?._id
  //   );
  //   const newData = [...cartProducts];
  //   if (findIndex != -1) {
  //     newData[findIndex].quantity = newData[findIndex].quantity + quantity;
  //   } else {
  //     newData.push({ product: e, quantity });
  //   }
  //   toast.success("Product added in cart successfully");
  // }

  return (
    <div>
      {/* <AfterLoginHeader /> */}
      <SideBarSkeleton>
        <section className={classes.section1}>
          {loading ? (
            <Loader height={"calc(100vh - 51px)"} />
          ) : (
            <Container>
              <Row className={classes.row}>
                <Col lg={{ span: 4, order: 1 }} xs={{ order: 2 }}>
                  <div className={classes.productInfo}>
                    <h4>{data?.name}</h4>
                    <ul>
                      <li>
                        <span>Code</span> <span>{data?.code}</span>
                      </li>
                      <li>
                        <span>Brimful Capacity (ml)</span>{" "}
                        <span>{data?.capacity}</span>
                      </li>
                      <li>
                        <span>Overall Height (mm)</span>{" "}
                        <span>{data?.height}</span>
                      </li>
                      <li>
                        <span>Major Axis Dia. D1 (mm)</span>{" "}
                        <span>{data?.majorAxis}</span>
                      </li>
                      <li>
                        <span>Minor Axis Dia. D2 (mm)</span>{" "}
                        <span>{data?.minorAxis}</span>
                      </li>
                      <li>
                        <span>Materials</span> <span>{data?.material}</span>
                      </li>
                      <li>
                        <span>Total Weight (gm)</span>{" "}
                        <span>{data?.weight}</span>
                      </li>
                      <li>
                        <span>Handle</span> <span>{data?.handle}</span>
                      </li>
                    </ul>
                    <p className={classes.desc}>
                      <ShowMoreShowLessText
                        text={data?.description}
                        visibility={400}
                      />
                    </p>

                    <h4>Product Advantages</h4>
                    <p className={classes.advantages}>
                      <ShowMoreShowLessText
                        text={data?.advantages}
                        visibility={400}
                      />
                    </p>
                    <h4>Usage Area/Industry</h4>
                    <ul className={classes.areaOrIndustryList}>
                      {data?.usageArea?.map((item, key) => (
                        <li key={key}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </Col>
                <Col lg={{ span: 8, order: 2 }} xs={{ order: 1 }}>
                  <ElasticCarousel
                    breakPoints={breakpoints}
                    customClass={classes.carousel}
                  >
                    {data?.images.map((item, key) => (
                      <div
                        className={classes.productImgDiv}
                        onClick={() => setSelectedProduct(item)}
                      >
                        <img src={imageUrl(item)} />
                      </div>
                    ))}
                  </ElasticCarousel>
                  <div className={classes.productImgFullViewDiv}>
                    <img src={imageUrl(data?.images[0])} />
                  </div>
                </Col>
                <Col
                  lg={{ span: 12, order: 3 }}
                  xs={{ order: 3 }}
                  className={classes.btns}
                >
                  <Button
                    variant="secondary"
                    onClick={() => {
                      navigate(`/edit-product/${data?.slug}`);
                    }}
                  >
                    Edit
                  </Button>
                  {/* <Button>Send Link</Button> */}
                </Col>
              </Row>
            </Container>
          )}
        </section>
      </SideBarSkeleton>
    </div>
  );
};

export default ProductDetail;
