import React, { useRef, useState } from "react";
import { BiSolidCategory, BiSolidDashboard } from "react-icons/bi";
import { HiMiniUsers } from "react-icons/hi2";
import { ImBriefcase } from "react-icons/im";
import { LiaFilterSolid } from "react-icons/lia";
import { MdLock } from "react-icons/md";
import {
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiNewspaperLine,
} from "react-icons/ri";
import { TbLogout } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { io } from "socket.io-client";
import { apiUrl } from "../../config/apiUrl";
import {
  CMSIcon,
  CareersIcon,
  ContactsIcon,
  LeadsIcon,
  ProductsIcon
} from "../../constant/imagePath";
import { signOutRequest } from "../../store/auth/authSlice";
import classes from "./SideBar.module.css";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state?.authReducer);

  const RenderItem = ({ icon, title, path, subMenu = [] }) => {
    const navigate = useNavigate();
    const active = useLocation()?.pathname;
    const [subnav, setSubnav] = useState(false);
    const subActive = subMenu.find((item, index) => item?.path == active);
    const showSubnav = () => setSubnav(!subnav);

    return (
      <>
        <div
          className={[
            classes?.listItemContainer,
            path == active && classes?.active,
            subActive && classes?.subActive,
            subnav && classes?.marginZero,
          ].join(" ")}
          onClick={() => {
            if (subMenu?.length > 0) {
              showSubnav(!subnav);
            } else {
              navigate(path);
            }
          }}
        >
          {icon}
          <span>{title}</span>
          {subMenu?.length > 0 &&
            (subnav ? (
              <RiArrowUpSFill
                size={20}
                color={"white"}
                className={classes?.dropDownIcon}
              />
            ) : (
              <RiArrowDownSFill
                size={20}
                color={"white"}
                className={classes?.dropDownIcon}
              />
            ))}
        </div>
        {subnav &&
          subMenu?.map((item, index) => {
            return (
              <div
                className={[
                  classes?.innerItemContainer,
                  item?.path == active && classes?.active,
                ].join(" ")}
                key={index}
                onClick={() => {
                  navigate(item?.path);
                }}
              >
                {item?.icon}
                <span>{item?.label}</span>
              </div>
            );
          })}
      </>
    );
  };

  const socket = useRef(null);

  const logout = () => {
    socket.current = io(apiUrl);
    socket.current.emit("logout", user?._id);
    dispatch(signOutRequest());
    navigate("/");
    window.location.reload();
  };

  return (
    <div className={classes?.mainContainer}>
      <div className={classes?.logoContainer}>
        {/* <img src={whiteLogo} onClick={() => navigate("/dashboard")} alt="..." /> */}
        <h3 style={{ color: "white" }} onClick={() => navigate("/dashboard")}>Demo</h3>
      </div>

      <RenderItem
        icon={<BiSolidDashboard size={22} />}
        title={"Dashboard"}
        path={"/dashboard"}
      />
      {user?.role == "admin" && (
        <RenderItem
          icon={<HiMiniUsers size={22} />}
          title={"Sub Admins"}
          path={"/sub-admins"}
        />
      )}
      {/* <RenderItem icon={<GiCheckMark size={20} />} title={"Transactions"} /> */}
      {/* <RenderItem icon={<HiUsers size={20} />} title={"All Users"} /> */}
      {(user?.role !== "admin"
        ? user?.permissions?.includes("products")
        : true) && (
        <RenderItem
          // icon={<BsChatSquareQuoteFill size={20} />}
          icon={
            <ReactSVG
              src={ProductsIcon}
              beforeInjection={(svg) => svg.classList.add(classes.reactSvg)}
            />
          }
          title={"All Products"}
          path={"/all-products"}
        />
      )}
      {(user?.role !== "admin" ? user?.role == "admin" : true) && (
        <RenderItem
          icon={<BiSolidCategory size={20} />}
          title={"Categories"}
          path={"/categories"}
        />
      )}
      {(user?.role !== "admin"
        ? user?.permissions?.includes("careers")
        : true) && (
        <RenderItem
          icon={
            <ReactSVG
              src={CareersIcon}
              beforeInjection={(svg) => svg.classList.add(classes.reactSvg)}
            />
          }
          title={"Careers"}
          path={"/careers"}
          subMenu={[
            {
              label: "All Jobs",
              icon: <ImBriefcase size={20} />,
              path: "/all-jobs",
            },
            {
              label: "Applicants",
              icon: (
                <ReactSVG
                  src={ContactsIcon}
                  beforeInjection={(svg) => svg.classList.add(classes.reactSvg)}
                />
              ),
              path: "/applicants",
            },
          ]}
        />
      )}
      {(user?.role !== "admin"
        ? user?.permissions?.includes("leads")
        : true) && (
        <RenderItem
          icon={
            <ReactSVG
              src={LeadsIcon}
              beforeInjection={(svg) => svg.classList.add(classes.reactSvg)}
            />
          }
          title={"Leads"}
          path={"/leads"}
          subMenu={[
            {
              label: "Products Leads",
              icon: (
                <ReactSVG
                  src={ProductsIcon}
                  beforeInjection={(svg) => svg.classList.add(classes.reactSvg)}
                />
              ),
              path: "/products-leads",
            },
            {
              label: "Contact Form Leads",
              icon: (
                <ReactSVG
                  src={ContactsIcon}
                  beforeInjection={(svg) => svg.classList.add(classes.reactSvg)}
                />
              ),
              path: "/contact-form-leads",
            },
          ]}
        />
      )}
      {(user?.role !== "admin"
        ? user?.permissions?.includes("filters")
        : true) && (
        <RenderItem
          path={"/filters"}
          icon={<LiaFilterSolid size={20} />}
          title={"Filters"}
        />
      )}
      {(user?.role !== "admin" ? user?.permissions?.includes("cms") : true) && (
        <RenderItem
          path={"/cms"}
          icon={
            <ReactSVG
              src={CMSIcon}
              beforeInjection={(svg) => svg.classList.add(classes.reactSvg)}
            />
          }
          title={"CMS"}
        />
      )}
      {(user?.role !== "admin"
        ? user?.permissions?.includes("newsletters")
        : true) && (
        <RenderItem
          path={"/newsletters"}
          icon={<RiNewspaperLine size={20} />}
          title={"NewsLetters"}
        />
      )}
      <RenderItem
        path={"/update-password"}
        icon={<MdLock size={20} />}
        title={"Update Password"}
      />

      <div className={[classes?.listItemContainer].join(" ")} onClick={logout}>
        <TbLogout size={22} />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default SideBar;
