import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { apiUrl, BaseURL } from "../../config/apiUrl";
import { signOutRequest, updateUser } from "../../store/auth/authSlice";

import { useState } from "react";
import { FaUserLock } from "react-icons/fa";
import { io } from "socket.io-client";
import { Button } from "../../Component/Button/Button";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import classes from "./OnHold.module.css";

const FormCard = ({ children, ...props }) => {
  return <div {...props}>{children}</div>;
};

const OnHold = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useRef(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const {
    accessToken: token,
    user,
  } = useSelector((state) => state?.authReducer);

  // get me
  const getMe = async () => {
    const url = BaseURL("users/me");
    setIsRefreshing(true);
    Get(url, token, false, true)
      .then((response) => {
        const apiUser = response?.data?.data?.user;
        dispatch(updateUser(apiUser));
      })
      .catch((err) => {})
      .finally(() => {
        setIsRefreshing(false);
      });
  };

  useEffect(() => {
    getMe();
  }, []);

  // handleLogout
  const handleLogout = async () => {
    socket.current = io(apiUrl);
    socket.current.emit("logout", user?._id);
    dispatch(signOutRequest());
    navigate("/");
  };

  return (
    <SideBarSkeleton>
      <FormCard className={classes?.main}>
        <div className={classes?.mainInner}>
          <FaUserLock size={25} />
          <div className={classes?.mainHeading}>
            <h4>Account Blocked</h4>
          </div>
          <div className={classes?.mainDescription}>
            <p>
              Your account has been blocked by admin. Thanks for your
              corporation.
            </p>
          </div>
          <div className={classes?.btnContainer}>
            <Button
              label={"Logout"}
              onClick={handleLogout}
              disabled={isRefreshing}
            />
            <Button
              label={isRefreshing ? "Please Wait..." : "Refresh"}
              onClick={getMe}
              disabled={isRefreshing}
            />
          </div>
        </div>
      </FormCard>
    </SideBarSkeleton>
  );
};

export default OnHold;
