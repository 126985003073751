import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import { BaseURL, apiHeader, validateEmail } from "../../config/apiUrl";
import { resetIcon } from "../../constant/imagePath";
import classes from "./ForgotPassword.module.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const submitHandler = async () => {
    if (!email) return toast.error("Please fill the Email field");
    if (!validateEmail(email))
      return toast.error("Please provide a valid email");
    setLoading(true);
    const response = await Post(
      BaseURL("users/forgotPassword"),
      { email },
      apiHeader()
    );
    if (response !== undefined) {
      toast.success(
        "Forgot password request has been sent successfully, Kindly check your email for the OTP"
      );
      navigate("/otp", { state: { email: email } });
    }
    setLoading(false);
  };

  useEffect(() => {
    isMobileViewHook(setIsMobile, 992);
  }, []);

  return (
    <div className={classes.mainContainer}>
      <Row className="gx-0">
        {!isMobile && (
          <Col xl={5} lg={5} md={12} className={classes.col1}>
            <div className={classes.logo}>
              {/* <img src={whiteLogo} className="mb-4" alt="" /> */}
              <h3
              style={{ color: "#fff" }}
              >Demo</h3>
            </div>

            <div className={classes.desc}>
              <p>Copyright 2022, All Rights Reserved.</p>
            </div>
          </Col>
        )}
        <Col xl={7} lg={7} md={12}>
          <div className={classes.col2}>
            <div className={classes.formWrapper}>
              <Button
                className={classes?.backBtn}
                variant="outlined-secondary"
                onClick={() => navigate("/")}
              >
                Back to Login
              </Button>
              <div className={classes.form}>
                <div className={classes.iconWrapper}>
                  <img src={resetIcon} />
                </div>
                <>
                  <div className={classes.content}>
                    <h3>Forgot Password </h3>
                    <p className={"muted"}>
                      Enter your email address to reset your password
                    </p>
                  </div>
                  <div className={classes.inputWrapper}>
                    <Input
                      value={email}
                      setter={setEmail}
                      leftIcon={
                        <BsFillEnvelopeFill
                          color={"var(--icon-color)"}
                          size={22}
                        />
                      }
                      placeholder={"Email Address"}
                      type={"email"}
                    />
                    <Button onClick={submitHandler} className={classes.btn}>
                      {loading ? "Wait..." : "Submit"}
                    </Button>
                  </div>
                </>
                {/* <p>
                Don’t have an account?{" "}
                <Link to={"/sign-up"} className={"link-main"}>
                  {" "}
                  Sign Up{" "}
                </Link>{" "}
              </p> */}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
