import React, { useState } from "react";
import { Button } from "../../Component/Button/Button";
import { DropDown } from "../../Component/DropDown/DropDown";
import { Input } from "../../Component/Input/Input";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddEditFilterModal.module.css";
const AddEditFilterModal = ({
  show,
  setShow,
  isApiCall,
  onClick,
  isEdit,
  categories,
  slug,
}) => {
  const [filter, setFilter] = useState("" || isEdit?.name);
  const [category, setCategory] = useState("" || isEdit?.category);

  return (
    <div>
      <ModalSkeleton
        setShow={setShow}
        show={show}
        width={"700px"}
        header={`${isEdit ? "Edit" : "Add"} Filter ${slug ? "Name" : "Title"}`}
        borderRadius={"20px"}

      >
        <div className={classes.main}>
          <div className={classes.body}>
            {!slug && (
              <div className={classes.inputDiv}>
                <DropDown
                  disabled={isEdit ? true : false}
                  label={"Category"}
                  placeholder="Select Category"
                  optionLabel={"name"}
                  optionValue={"id"}
                  value={category}
                  setter={setCategory}
                  options={categories}
                />
              </div>
            )}

            <div className={classes.inputDiv}>
              <Input
                label={slug ? "Filter Name" : "Filter Title Name"}
                placeholder={
                  slug ? "Enter Filter Name" : "Enter Filter Title Name"
                }
                value={filter}
                setter={setFilter}
              />
            </div>
          </div>

          <div className={"text-center my-4"}>
            <Button
              disabled={isApiCall}
              label={isApiCall ? "Please Wait..." : "Submit"}
              onClick={() =>
                onClick(
                  {
                    filter,
                    category,
                  },
                  isEdit ? true : false
                )
              }
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default AddEditFilterModal;
