import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import OnHold from "../pages/OnHold/OnHold";
import { subAdminPermissionsOptions } from "../constant/commonData";

function ProtectedRouter({ element: Element }) {
  const slug = useParams()?.slug;
  const isAuthenticated = useSelector((state) => state.authReducer.isLogin);
  const user = useSelector((state) => state.authReducer.user);
  const isBlockedByAdmin = useSelector(
    (state) => state.authReducer.user?.isBlockedByAdmin
  );

  const hasPermission =
    user?.role === "admin"
      ? true
      : [
          ...subAdminPermissionsOptions
            ?.filter((e) => user?.permissions?.includes(e?.value))
            ?.map((e) => e?.path?.map((a) => a?.replace(":slug", slug))),
          "/update-password",
          "/dashboard",
        ]?.some((e) => e?.includes(window.location.pathname));

  return (
    <>
      {isAuthenticated ? (
        !hasPermission ? (
          <Navigate replace to="/dashboard" />
        ) : isBlockedByAdmin ? (
          <OnHold />
        ) : (
          Element
        )
      ) : (
        <Navigate replace to="/" />
      )}
    </>
  );
}
export default ProtectedRouter;
