/* eslint-disable */

import React, { useState, useEffect } from 'react';
import classes from './TermsAndConditions.module.css';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '../../Component/Button/Button';
import { BaseURL, apiHeader } from '../../config/apiUrl';
import { Get, Patch } from '../../Axios/AxiosFunctions';
import { toast } from 'react-toastify';
import { Loader } from '../../Component/Loader';
import QuillInput from '../../Component/QuillInput';

const TermsAndConditions = () => {
  const { accessToken } = useSelector((state) => state.authReducer);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState('');

  const getData = async () => {
    const url = BaseURL('terms-and-policy?type=terms');
    setIsLoading(true);

    const response = await Get(url, accessToken);
    setIsLoading(false);

    if (response) {
      setData(response?.data?.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async () => {
    const url = BaseURL(`terms-and-policy/${data?._id}`);
    const params = {
      content: data?.content,
    };
    if(params?.content?.includes('<p><br></p>') || !params?.content){
      return toast.error('Please fill the content')
    }
    setBtnLoading(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    if (response) {
      toast.success('Terms And Conditions updated successfully');
    }
    setBtnLoading(false);
  };
  return (
    <SideBarSkeleton>
      <Container className={classes.main}>
        {isLoading ? (
          <Loader />
        ) : (
          <Row className={classes.row}>
            <Col md={12}>
              <h3>Terms And Conditions</h3>
            </Col>
            <Col md={12}>
              <QuillInput
                setter={(e) => setData((prev) => ({ ...prev, content: e }))}
                value={data?.content}
                quillClass={classes.quillInput}
              />
            </Col>

            <Col md={12}>
              <Button label={btnLoading ? 'Wait...' : 'Submit'} onClick={handleSubmit} />
            </Col>
          </Row>
        )}
      </Container>
    </SideBarSkeleton>
  );
};

export default TermsAndConditions;
