import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import SubHeader from "../../Component/SubHeader";
import TableSkeleton from "../../Component/TableSkeleton";
import useDebounce from "../../CustomHooks/useDebounce";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import ViewContactFormModal from "../../modals/ViewContactFormModal";
import classes from "./ContactFormLeads.module.css";

const ContactFormLeads = () => {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(20);

  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState("");
  const searchText = useDebounce(search, 500);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);



  async function getData(pg = page) {
    const url = BaseURL(
      `contact-us/?page=${pg}&limit=${recordsLimit}&search=${searchText}`
    );
    setLoading(true);
    const response = await Get(url, accessToken);
    setLoading(false);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
    }
  }

  useEffect(() => {
    setPage(1);
    getData(1);
  }, [searchText]);

  return (
    <>
      <style>{`
      .table100-body{
        height:calc(100vh - 330px);
        overflow-y:scroll;
      }
  @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }

      `}</style>
      <div>
        <SideBarSkeleton>
          <Container className={classes.main}>
            {/* table */}
            <Row className={classes.row}>
              <Col md={12}>
                <SubHeader
                  title={"Contact Form Leads"}
                  search={search}
                  setSearch={setSearch}
                  status={status}
                  setStatus={setStatus}
                />
              </Col>
              <Col md={12} className={classes.tableMain}>
                <div class="table100 ver1 m-b-110">
                  <div class="table100-head">
                    <table>
                      <thead>
                        <tr class="row100 head">
                          <th
                            class="cell100 column1"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Name
                          </th>
                          <th
                            class="cell100 column2"
                            style={{ width: "15%", textAlign: "start" }}
                          >
                            Email
                          </th>

                          <th
                            class="cell100 column3"
                            style={{ width: "55%", textAlign: "start" }}
                          >
                            Message
                          </th>

                          <th
                            class="cell100 column4"
                            style={{ width: "15%", textAlign: "center" }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {loading ? (
                    <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                  ) : (
                    <div className={`table100-body js-pscroll ps ps--active-y`}>
                      <table>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr
                                class="row100 body"
                              
                              >
                                <td
                                  class="cell100 column1"
                                  style={{ width: "15%", textAlign: "left" }}
                                >
                                  {item?.name}
                                </td>
                                <td
                                  class="cell100 column2"
                                  style={{
                                    width: "15%",
                                    textAlign: "left",
                                  }}
                                >
                                  <div className={classes.descCol}>
                                    {item?.email}
                                  </div>
                                </td>

                                <td
                                  class="cell100 column4"
                                  style={{ width: "55%", textAlign: "left" }}
                                >
                                  <p className={"text1Line"}>{item?.message}</p>
                                </td>

                                <td
                                  class="cell100 column5"
                                  style={{ width: "15%" }}
                                >
                                  <div className={classes.actions_btn}>
                                    <Button
                                      className={classes.viewDetailBtn}
                                      label={"View Detail"}
                                      variant="primary"
                                      onClick={() => {
                                        setSelectedItem(item);
                                        setShowModal("view");
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <NoData text={"No Contact Form Found"} />
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={12} className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e);
                    }}
                    currentPage={page}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </SideBarSkeleton>
        {showModal == "view" && (
          <ViewContactFormModal
            setShow={setShowModal}
            show={showModal}
            data={selectedItem}
          />
        )}
      </div>
    </>
  );
};
export default ContactFormLeads;
