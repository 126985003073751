import React, { useState } from 'react';
import classes from './UpdatePassword.module.css';
import { Input } from '../../Component/Input/Input';
import { Button } from '../../Component/Button/Button';
import { Row, Col, Container } from 'react-bootstrap';
import SidebarSkeleton from '../../Component/SideBarSkeleton';
import { Patch } from '../../Axios/AxiosFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { apiHeader, BaseURL } from '../../config/apiUrl';
import { saveLoginUserData } from '../../store/auth/authSlice';

function UpdatePassword() {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // update password
  const handleSubmit = async () => {
    let params = {
      passwordCurrent: currentPassword,
      password: newPassword,
      passwordConfirm: confirmPassword
    };
    for (const key in params) {
      if (params[key] == '' || params[key] == null) {
        toast.error('Please fill all the fields');
        return;
      }
    }
    if (currentPassword?.length < 8 || confirmPassword?.length < 8 || newPassword?.length < 8) {
      toast.error('Passwords must be at least 8 characters long');
      return;
    }
    if (currentPassword == newPassword) {
      toast.error('Current Password and New Password can not be same');
      return;
    }

    if (newPassword !== confirmPassword)  {
      toast.error('New Password and Confirm Password does not match');
      return;
    }

    setLoading(true);
    const response = await Patch(BaseURL('users/updateMyPassword'), params, apiHeader(accessToken));
    setLoading(false);
    if (response !== undefined) {
      dispatch(saveLoginUserData(response?.data));
      setCurrentPassword('');
      setConfirmPassword('');
      setNewPassword('');
      toast.success('Password updated successfully');
    }
  };

  return (
    <SidebarSkeleton>
      <Container className={classes.main}>
        <Row>
          <Col md={12}>
            <div className={classes.pageHeader}>
              <h2>Update Password</h2>
            </div>
          </Col>
          <Col md={12}>
            <div className={classes.boxShadow__wrapper}>
              <Row className={classes.formRow}>
                <Col md={7} sm={12} className={classes.input__wrapper}>
                  <Input
                    label="Current Password"
                    value={currentPassword}
                    setter={setCurrentPassword}
                    type="password"
                    placeholder={'Enter here'}
                  />
                </Col>
                <Col md={7} sm={12} className={classes.input__wrapper}>
                  <Input
                    label="New Password"
                    value={newPassword}
                    setter={setNewPassword}
                    type="password"
                    placeholder={'Enter here'}
                  />
                </Col>
                <Col md={7} sm={12} className={classes.input__wrapper}>
                  <Input
                    label="Confirm New Password"
                    value={confirmPassword}
                    setter={setConfirmPassword}
                    type="password"
                    placeholder={'Enter here'}
                  />
                </Col>

                <Col md={12} className={'jCenter'}>
                  <Button
                    onClick={handleSubmit}
                    className={classes.saveBtn}
                    disabled={isLoading}
                    label={isLoading ? 'Updating...' : 'Update'}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </SidebarSkeleton>
  );
}

export default UpdatePassword;
