import React, { useEffect, useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import { TextArea } from "../../Component/TextArea";
import { Input } from "../../Component/Input/Input";
import { Button } from "../../Component/Button/Button";
import classes from "./FaqModal.module.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

const FaqModal = ({ show, setShow, loading, onClick, data }) => {
  const [question, setQuestion] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (data) {
      setQuestion(data?.question);
      setDescription(data?.answer);
    }
  }, [data]);

  return (
    <>
        <ModalSkeleton show={show} setShow={setShow} header={`${data?'Edit' :'Add'} FAQ`}
        borderRadius={20}
        >
          <Row className={classes.row}>
            <Col md={12}>
              <Input
                label={"Enter Question"}
                setter={setQuestion}
                value={question}
                placeholder={"Enter Question"}
              />
            </Col>
            <Col md={12}>
              <TextArea
                label={"Enter Description"}
                setter={setDescription}
                value={description}
                placeholder={"Enter Description"}
              />
            </Col>
            <Col md={12}>
              <div className={classes.btnsDiv}>
                <Button
                  label={loading ? "Wait..." : data? "Update" : "Submit"}
                  onClick={() => {
                    const errorParams = {
                      question,
                      answer: description,
                    };
                    for (let i in errorParams) {
                      if (!errorParams[i]) {
                        return toast.error("Please fill all the fields");
                      }
                    }
                    onClick(
                      { question, answer: description },
                      data?._id || undefined
                    );
                  }}
                  disabled={loading}
                />
              </div>
            </Col>
          </Row>
        </ModalSkeleton>
    </>
  );
};

export default FaqModal;
