import Parser from "html-react-parser";
import React from "react";
import { Col, Row } from "react-bootstrap";
import UploadImageBox from "../../Component/UploadImageBox";
import { imageUrl } from "../../config/apiUrl";
import ModalSkeleton from "../ModalSkeleton";
import styles from "./ViewSustainabilityModal.module.css";

const ViewSustainabilityModal = ({ show, setShow, data }) => {
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"View Sustainability"}
      borderRadius={"20px"}
      showCloseIcon
      modalClass={styles.modalClass}
    >
      <Row className={styles.row}>
        <Col md={12}>
          <UploadImageBox state={imageUrl(data?.image)} edit={false} label={'Photo'} />
        </Col>
        <Col md={12}>
          <UploadImageBox state={imageUrl(data?.coverPhoto)} edit={false} label={'Hero Section Photo'} />
        </Col>
        <Col md={12}>
          <label>Title:</label>
          <p>{data?.title}</p>
        </Col>
        <Col md={12}>
          <label>Type:</label>
          <p>{data?.sustainabilityStatus}</p>
        </Col>

        <Col md={12}>
          <label>Description:</label>
          <div className={styles?.desc}>{Parser(data?.description)}</div>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default ViewSustainabilityModal;
